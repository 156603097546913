import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPower } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import Lagenhet from './Lagenhet';
import Lokal from './Lokal';
import React, { useState, useEffect } from 'react';
import * as TriggerRemindersService from '../../../services/automation/TriggerRemindersService';
import AutomationDisplay from './AutomationDisplay';

function StyledLink({ onClick, children, isActive, ...props }) {
  return (
    <Button
      onClick={onClick}
      {...props}
      style={{
        background: 'transparent',
        border: '0',
        margin: '0',
        padding: '0 0 5px 5px',
        fontWeight: isActive ? 'bold' : 'normal',
        textDecoration: isActive ? 'underline' : 'none',
        color: "black"
      }}
    >
      {children}
    </Button>
  );
}

const Intresseanmalan = ({ onLogout }) => {
  const [activeTable, setActiveTable] = useState('lagenhet');
  const [activeStatus, setActiveStatus] = useState(0);
  const [responseMessage, setResponseMessage] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!hasLoaded) {
      const data = []; // Your data object
      TriggerRemindersService.triggerReminders(data)
        .then((response) => {
          setResponseMessage(response.data);
          setHasLoaded(true);
        })
        .catch((error) => {
          console.error("Error triggering reminders:", error);
        });
    }
  }, [hasLoaded]);

  return (
    <div>
      <nav className="navbar px-2">
        <div className="mr-auto">
          <h2>Intresseanmälan
            <StyledLink
              title="Logga ut"
              onClick={() => onLogout()}
            >
              <BsPower size={25} color={"yellow"} />
            </StyledLink>
          </h2>
        </div>
        <div className="mr-auto navbar-expand-lg">
          <ul className="navbar-nav">
            <li className="nav-item mx-2">
              <StyledLink
                isActive={activeTable === 'lagenhet'}
                onClick={() => setActiveTable('lagenhet')}
              >
                <h5>Lägenhet</h5>
              </StyledLink>
            </li>
            <li className="nav-item mx-2">
              <StyledLink
                isActive={activeTable === 'lokal'}
                onClick={() => setActiveTable('lokal')}
              >
                <h5>Lokal</h5>
              </StyledLink>
            </li>
          </ul>
        </div>
        <div className="mr-auto navbar-expand-lg">
          <ul className="navbar-nav">
            <li className="nav-item mx-2">
              <StyledLink
                isActive={activeStatus === 0}
                onClick={() => setActiveStatus(0)}
              >
                <h5>Aktuella</h5>
              </StyledLink>
            </li>
            <li className="nav-item mx-2">
              <StyledLink
                isActive={activeStatus === 1}
                onClick={() => setActiveStatus(1)}
              >
                <h5>Parkerade</h5>
              </StyledLink>
            </li>
            <li className="nav-item mx-2">
              <StyledLink
                isActive={activeStatus === 2}
                onClick={() => setActiveStatus(2)}
              >
                <h5>Arkiverade</h5>
              </StyledLink>
            </li>
          </ul>
        </div>
      </nav>
      <div className="p-2">
        { responseMessage && <AutomationDisplay data={responseMessage} /> }
      </div>
      <div>
        {activeTable === 'lagenhet'
          ? <Lagenhet match={{ params: { arkiv: activeStatus } }} />
          : <Lokal match={{ params: { arkiv: activeStatus } }} />
        }
      </div>
    </div>
  );
}

export default Intresseanmalan;
