import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext([{}, () => {}]);
export const useUser = () => useContext(UserContext);

// As we wrap all, the default will be immediately set.
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ isAuthenticated: false, email: null });
  // Additional user-specific state and functionality goes here...
  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};
