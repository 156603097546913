function ModalWithButton({ button, modal: InputModal, modalId, setActiveModal, activeModal }) {
  const isOpen = modalId === activeModal;

  const handleOpen = (event) => {
    if (event) event.preventDefault();
    setActiveModal(modalId);
  };

  const handleOnClose = () => {
    setActiveModal(null);
  };

  const Button = button;
  return (
    <>
      <Button onClick={handleOpen} />
      {isOpen && <InputModal onClose={handleOnClose} setActiveModal={setActiveModal}/>}
    </>
  );
}

export default ModalWithButton;
