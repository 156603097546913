import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import { useState } from 'react';

// Components
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import ScrollToTop from './components/ScrollToTop';

// Pages
import Blog from './pages/blog';
import Fastigheter from './pages/fastigheter';
import GDPR from './pages/gdpr';
import Hem from './pages/hem';
import Hyresinfo from './pages/hyresinfo';
import Ledigt from './pages/ledigt';
import Om from './pages/om';
import Renew from './pages/intresseanmalan/renew';

// Apps
import AdminApp from './components/intresseanmalan/admin/AdminApp';

// App
function App() {
  const [activeModal, setActiveModal] = useState(null);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Navigation activeModal={activeModal} setActiveModal={setActiveModal} />
      <Routes>
        <Route exact path='/' element={<Hem setActiveModal={setActiveModal}/>} />
        <Route path='/om' element={<Om/>} />
        <Route exact path='/ledigt' element={<Ledigt />} />
        <Route path='/hyresinfo' element={<Hyresinfo/>} />
        <Route exact path='/fastigheter' element={<Fastigheter />} />
        <Route exact path='/gdpr' element={<GDPR />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route exact path="/control" element={<AdminApp />} />
        <Route exact path="/intresseanmalan/renew" element={<Renew setActiveModal={setActiveModal}/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
