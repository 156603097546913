import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useState, useRef } from 'react';
import Employees from '../components/Employees';
import IntroCarousel from '../components/Carousel';
import MyMap from '../components/MyMap';
import Projects from '../components/Projects';
import React from 'react';
import RealEstates from '../components/RealEstates';

const Hem = ({ setActiveModal }) => {
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const mapRef = useRef(null);

  // Handle marker selection
  const handleMarkerSelect = (markerId) => {
    setSelectedMarkerId(markerId);
    if (mapRef.current) {
      mapRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container>
      {/* Carousel */}
      <Row id="introCarousel" className="px-4 my-5">
        <IntroCarousel />
      </Row>

      {/* Introduction */}
      <Row id="introduction" className="px-4 my-5">
        <h2>Välkommen</h2>
        <div className="mb-3">
          Är du på jakt efter ditt nya hem eller den perfekta lokalen för din verksamhet? Registrera
          ditt <Button onClick={() => setActiveModal('intresseanmalan-link')}>intresse</Button> eller
          utforska våra <Button onClick={() => setActiveModal('ledigt-link')}>lediga lokaler</Button> för
          att inte missa våra erbjudanden.
        </div>
        <div className="mb-3">
          Sedan snart 20 år tillbaka har Umehem varit en drivande aktör i fastighetsutvecklingen i
          Umeå, där vi erbjuder lägenheter och kommersiella lokaler i stadens hjärta. Med
          hyresgästen i centrum skapar vi attraktiva miljöer för boende och arbete.
        </div>
      </Row>

      {/* Newest Project */}
      <Row id="ongoing" className="px-4 my-5 g-4">
        <Col sm={6}>
          <Image src={`${process.env.PUBLIC_URL}/images/projects/frigg7.jpg`} className="d-block w-100" fluid rounded alt="Nyproduktion av hyreslägenheter i Valandhuset" aria-label="Nyproduktion av hyreslägenheter i Valandhuset" />
        </Col>
        <Col sm={6}>
          <h2>Nyproduktion av Hyreslägenheter</h2>
          <div className="mb-3">
            Mitt i Umeås puls utvecklar vi för tillfället Valandhuset med hållbara byggmetoder och
            material. Genom att välja massivträ som byggmaterial minskar vi miljöpåverkan,
            förbättrar brandsäkerheten och skapar en sund inomhusmiljö.
          </div>
        </Col>
      </Row>

      {/* Old Projects */}
      <Row id="projects" className="px-4 my-5">
        <h2>Våra Projekt</h2>
        <div className="mb-3">
          Se exempel på våra tidigare projekt här.
        </div>
        <Projects id="projects" />
      </Row>

       {/* About Us */}
       <Row id="om" className="px-4 mb-5">
        <h2>Om Oss</h2>
        <p className="mb-3">
          Umehem är ett lokalt och privatägt fastighetsbolag som grundades 2006 och är en del av en
          koncern som inkluderar
          byggbolaget <a href="https://www.arbygg.se" aria-label="Besök AR Bygg">AR Bygg</a> och
          arkitektbolaget <a href="https://www.artark.se" aria-label="Besök AR.T">AR.T</a>.
          Tillsammans har vi möjlighet att utföra nybyggnationer, renoveringar och ombyggnationer,
          allt till fördel för våra hyresgäster. Vårt team består av ~40 erfarna medarbetare med
          expertis inom diverse områden, och vi arbetar kontinuerligt för att uppfylla våra
          hyresgästers behov och förbättra deras livsmiljöer.
        </p>
        <Employees />
      </Row>

      {/* Real Estate and Map */}
      <Row id="googleMap" className="px-4 my-3">
        <h2>Våra Fastigheter</h2>
        <div className="mb-3">
          Utforska våra fastigheter på kartan. Vårt utbud omfattar bostäder och kommersiella
          lokaler som främst är belägna i centrala Umeå.
        </div>
        <div>
          Umehem kontoret <img src={process.env.PUBLIC_URL + "/images/mapicons/yellowBall.png"} alt="Umehem kontoret" aria-label="Umehem kontoret" /> |
          Kontor/Butiker/Lokaler <img src={process.env.PUBLIC_URL + "/images/mapicons/blueBall.png"} alt="Kontor/Butiker/Lokaler" aria-label="Kontor/Butiker/Lokaler" /> |
          Hyresrätter <img src={process.env.PUBLIC_URL + "/images/mapicons/redBall.png"} alt="Hyresrätter" aria-label="Hyresrätter" />
        </div>
        <div className="m-0 px-0" ref={mapRef}>
          <MyMap selectedMarkerId={selectedMarkerId} />
        </div>
        <div className="mb-3 px-2">
          <RealEstates onMarkerSelect={handleMarkerSelect} />
        </div>
      </Row>
    </Container>
  );
};

export default Hem;
