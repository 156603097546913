// import available from "../resources/ledigt.json";
import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Col, Row } from 'react-bootstrap';
import { decode } from 'html-entities';
import { useEffect, useState } from 'react';
import axios from 'axios';
import realEstates from "../resources/realEstates.json";

axios.defaults.withCredentials = true;

const styles = {
  cardText: {
  },
  cardImage: {
    objectFit: 'cover',
    height: '30vh'
  }
}

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Ledigt = ({ onClose, onLogout }) => {
  const [available, setAvailable] = useState([]);

  useEffect(() => {
    axios.get(`${API_URL + "/json/ledigt"}`)
      .then(response => {
        setAvailable(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, []);

  const nCols = 1;
  const colClass = "col-md-" + (12 / nCols);
  return (
    <Row md={nCols}>
      {available.map(offer => {
        if (offer.show) {
          const availableRealEstate = realEstates.find(realEstate => realEstate.idx === offer.realEstateIdx)
          if (availableRealEstate) {
            return (
              <Col key={offer.idx} className={`${colClass} g-2`}>
                <Card className="h-100">
                  <Card.Img
                    style={styles.cardImage}
                    variant="top"
                    src={process.env.PUBLIC_URL + offer.overrideImage}
                  />
                  <Card.Body>
                    <Card.Title>
                      {decode(availableRealEstate.name)}
                      <Card.Subtitle className="mt-2 text-muted">
                        {decode(availableRealEstate.address)}
                      </Card.Subtitle>
                    </Card.Title>
                    <div style={{
                      display: 'flex'
                      , flexDirection: 'column'
                      , justifyContent: 'flex-start'
                      , height: '100%'
                    }}
                    >
                      <Card.Text className="mb-1">
                        <span className="pb-2">
                          <strong>Lokalyta:</strong> {offer.area}<br />
                          <strong>Ledig:</strong> {offer.date}<br />
                          <strong>Övrigt:</strong> {offer.extra}
                        </span>
                        <span>
                          <em>
                            {offer.description}
                          </em>
                        </span>
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
          } else {
            return <></>;
          }
        } else {
          return <></>;
        }
      })}
    </Row>
  )
};

export default Ledigt;