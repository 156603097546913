import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import React from 'react';

const GDPR = () => {
  return (
    <Container>
      <h2>Vår GDPR och Användning av Cookies Policy</h2>
      <div className="pb-3">
        Allmänna datskyddsförordningen <a href="https://gdprinfo.eu/sv">GDPR</a> gäller i hela
        EU/EES och har till syfte att skapa en enhetlig och likvärdig nivå för skyddet av
        personuppgifter så att det fria flödet av uppgifter inom Europa inte hindras.
      </div>
      <div className="pb-3">
        I denna policy redogör vi för varför och hur vi samlar in och använder dina personuppgifter.
        Den beskriver också dina rättigheter gentemot oss och hur du kan göra dina rättigheter
        gällande. Du kan alltid kontakta oss vid frågor kring integritets- och dataskydd genom att
        skicka ett e-postmeddelande till <a href="mailto:gdpr@umehem.se">gdpr@umehem.se</a>.
      </div>

      <h3>Personuppgiftsansvarig</h3>
      <div className="pb-3">
        Skyddet för din personliga integritet är viktigt för oss, oavsett om det är våra hyresgästers,
        anställdas eller samarbetspartners personuppgifter som vi behandlar. Umehem Fastigheter AB,
        Nygatan 13, 903 27 Umeå, Organisationsnummer 556701-2470 tillsammans med koncernföretag är
        personuppgiftsansvarig för behandlingen av dina personuppgifter.
      </div>

      <h3>Vilken information samlas in?</h3>
      <div className="pb-3">
        Du kan direkt eller indirekt komma att lämna information om dig själv, t.ex. när du kontaktar
        oss i olika ärenden eller när du betalar din hyra. Detta kan vara person- och
        kontaktinformation (namn, personnummer, aviseringsadress, e-postadress, mobiltelefonnummer,
        etc.) eller betalningsinformation (bankkontonummer, hyresinbetalningar etc.).
      </div>

      <h3>Vad gör vi med din information?</h3>
      <div className="pb-3">
        Dina personuppgifter kan komma att lämnas ut till andra företag och organisationer som vi
        samarbetar med för vår fastighetsförvaltning. Vi kan komma att lämna nödvändig information
        till myndigheter såsom kronofogden, skatteverket eller andra myndigheter om vi är skyldiga
        att göra det enligt lag eller om du har samtyckt till att vi gör det. Vi ämnar behandla alla
        dina personuppgifter inom EU/EES (om det krävs att uppgifter överförs till ett land utanför
        EU/EES så säkerställer vi att sådan överföring är laglig). Vi sparar din personuppgifter
        endast så länge som det är nödvändigt för att utföra våra åtaganden gentemot dig och så
        länge det krävs enligt lagstadgade lagringstider.
      </div>

      <h4 id="intresseanmalan">Hur hanteras personuppgifter i samband med en intresseanmälan?</h4>
      <div className="pb-3">
        De personuppgifter du lämnar i samband med en intresseanmälan behandlas i den utsträckning
        som behövs för att kunna göra en bedömning av om du kan godtas som hyresgäst. Därför behövs
        uppgifter från personer som kan uttala sig om dig. Genom att lämna uppgifter om
        referenspersoner medger du att uppgifter får inhämtas från dessa personer. Uppgifter kan
        också komma att inhämtas från kreditupplysningsinstitut och myndigheter. Via vår hemsida
        ger vi dig möjlighet att logga in för att:
        <ul>
          <li>skapa</li>
          <li>se</li>
          <li>anpassa</li>
          <li>förlänga</li>
          <li>radera</li>
          <li>ladda ner (till CSV fil)</li>
        </ul>
        din intresseanmälan. En skapad/förlängd/anpassad intresseanmälan gäller i 6 månader från
        datumet den skapades/förlängdes/anpassades, därefter raderas den. Innan den raderas skickas
        en påminnelse ut via email med förfrågan om förlängning/anpassning. Förlängs/anpassas den
        inte så raderas den automatiskt.
      </div>

      <h4>Hur hanteras personuppgifter i samband med hyreserbjudande?</h4>
      <div className="pb-3">
        När du erbjuds bostad/lokal behöver vi behandla fler personuppgifter om dig, exempelvis
        uppgifter om din ekonomi, om din anställning och hur du skött tidigare boenden och
        hyresinbetalningar. Det innebär att vi hämtar in och behandlar uppgifter från exempelvis:
        <ul>
          <li>kreditupplysning</li>
          <li>inkassobolag</li>
          <li>kronofogdsmyndigheten</li>
          <li>angivna referenspersoner</li>
          <li>anställningsintyg och andra intyg (inlämnade från dig)</li>
          <li>förvaltare eller god man (om du har en sådan)</li>
          <li>beslut från socialnämnden (om du erbjudits särskilt boende eller gruppboende)</li>
          <li>
            antagningsbesked, kårmedlemskap eller annat intyg som styrker studier (om du erbjudits
            studentbostad)
          </li>
        </ul>
        Vi sparar dina uppgifter så länge de är relevanta och aktuella. Kreditupplysning, uppgifter
        från inkassobolag och myndigheter som samlats in för att godkänna dig som hyresgäst sparar
        vi inte. Om du blir nekad pga att vi inte kan erbjuda dig som hyresgäst så kommer vi att
        bevara personuppgifterna i tre månader efter avslagsbeslutet.
      </div>

      <h4>Hur hanteras personuppgifter under hyrestiden?</h4>
      <div className="pb-3">
        För att kunna fullgöra våra skyldigheter som hyresvärd och avtalspart behandlar vi dina
        personuppgifter under
        hyrestiden. Exempelvis kan dina personuppgifter komma att behandlas vid:
        <ul>
          <li>utskick av hyresavier</li>
          <li>eventuella anslutningar till bredband</li>
          <li>bokföring/behandlingar av dina betalningar</li>
          <li>felanmälningar</li>
          <li>löpande underhåll</li>
          <li>
            boknings- och passageloggning (i gemensamma utrymmen där vi har elektroniska nycklar)
          </li>
          <li>uppgifter om förbrukning av el/vatten/värme</li>
          <li>hyresförhandlingar</li>
          <li>utskick av information till dig som du behöver</li>
          <li>
            eventuell uppdatering av dina personuppgifter mot offentliga register (för att
            säkerställa att de är korrekta)
          </li>
          <li>kontroller att du studerar (vid boende i studentbostad)</li>
          <li>eventuell hantering av skyddade personuppgifer</li>
          <li>eventuell försäljning av fastigheten</li>
          <li>
            rättsliga anspråk (ex. vid störningar i boendet, sena eller uteblivna
            hyresbetalningar eller skador i lägenhet/lokal)
          </li>
          <li>
            för att tillhandahålla, förbättra och utveckla vår service, produkter och tjänster
            till dig
          </li>
          <li>för att marknadsföra oss och våra tjänster</li>
        </ul>
        Dina personuppgifter kan komma att lämnas ut:
        <ul>
          <li>
            till personuppgiftsbiträde för behandling av dina perosnuppgifter. Det kan gälla en
            entreprenör som ska reparer något i din lägenhet, ett företag som tillhandahåller
            bredband eller el, ett inkassoföretag eller ett företag som sköter vårt IT- eller
            bokföringssystem
          </li>
          <li>
            till företag (ex. bredbandsbolag, försäkringsbolag och elbolag) för att de ska kunna
            marknsadsföra sina produkter som du kan ha nytta av i samband med hyreförhållandet
          </li>
          <li>
            till socialnämnden och andra myndigheter vid rättsliga anspråk (ex. vid störningar i
            boendet, sena eller uteblivna hyresbetalningar eller skador i lägenhet/lokal)
          </li>
          <li>
            vid en eventuell försäljning av fastigheten till köparen
          </li>
          <li>
            när det följer av lag eller myndighetsbeslut
          </li>
        </ul>
        Vi sparar dina personuppgifter under den tid de är relevanta. Ett borgensåtagande, beslut om
        förvaltare eller god man raderar vi två år efter åtagandets eller beslutet har upphört att
        gälla.
      </div>

      <h4>Hur hanteras personuppgifter efter hyrestiden?</h4>
      <div className="pb-3">
        Dina personuppgifter kommer att tas bort efter hyrestidens slut förutom vissa dokument som
        måste sparas i minst två år som exempelvis:
        <ul>
          <li>
            hyresavtal
          </li>
          <li>
            beslut från socialnämnd
          </li>
          <li>
            studieintyg
          </li>
          <li>
            uppgifter om dig som finns i vårt bokföringsmaterial (måste sparas i sju år då
            bokföringslagen kräver detta)
          </li>
        </ul>
      </div>

      <h3>Dina rättigheter</h3>
      <div className="pb-3">
        <ul>
          <li>
            <strong>Rätt att få tillgång till din data</strong><br />
            Du kan begära en kopia av de uppgifter som vi behandlar om dig, ett s.k. registerutdrag.
          </li>
          <li>
            <strong>Rätt till rättelse</strong><br />
            Du har rätt att få felaktiga personuppgifter som behandlas av dig korrigerade.
          </li>
          <li>
            <strong>Rätt att bli raderad (rätten att bli bortglömd)</strong><br />
            Du har rätt att begära att dina personuppgifter tas bort om desamma inte längre är
            nödvändiga för det syfte de blev insamlad för. Det kan dock finnas legala skyldigheter
            för oss att spara uppgifter, som hindrar oss från att omedelbart radera vissa av dina
            personuppgifter. Dessa skyldigheter kommer från bokförings- och skattelagstiftning och
            från konsumenträttslagstiftning. Vi kommer då att blockera de personuppgifter som vi är
            skyldiga att spara, från att kunna användas till andra syften än att uppfylla sådana
            legala skyldigheter.
          </li>
          <li>
            <strong>Rätt att invända mot behandling</strong><br />
            Vidare har du också rätt att i vissa fall invända mot och begära begränsning av viss
            behandling av personuppgifter om dig.
          </li>
          <li>
            <strong>Rätt till dataportabilitet</strong><br />
            Du har också i flera situationer rätt till dataportabilitet av de personuppgifter som du
            själv tillhandahållit oss.
          </li>
          <li><strong>Rätt till klagommål</strong><br />
            Du har också rätt att vända dig till oss gdrp@umehem.se
            eller <a href="https://www.imy.se/">Integritetsskyddsmyndigheten</a> med eventuella
            klagomål vad gäller vår behandling av dina personuppgifter.
          </li>
        </ul>
      </div>

      <h3>Användning av cookies</h3>
      <div className="pb-3">
        Denna webbplats använder cookies för att förbättra användarupplevelsen och optimera
        webbplatsens prestanda. Cookies är små textfiler som lagras på din enhet när du besöker en
        webbplats. De hjälper oss att komma ihåg dina preferenser och tillhandahålla grundläggande
        webbplatsfunktioner.
      </div>
      <div className="pb-3">
        Vi använder sessionscookies, som raderas när du stänger din webbläsare. Dessa cookies
        hjälper till att behålla information under en webbläsarsession, vilket är viktigt för vår
        webbplats funktion. Dessa cookies kommer direkt från vår webbplats (förstapartscookies).
      </div>
      <div className="pb-3">
        Du har rätt att acceptera eller avvisa cookies. De flesta webbläsare accepterar cookies
        automatiskt, men du kan ändra dina webbläsarinställningar för att radera cookies eller
        förhindra att de accepteras automatiskt. Observera dock att vissa funktioner på vår
        webbplats kanske inte fungerar korrekt om du väljer att avvisa cookies.
      </div>

      ----
      <br />
      <i>Policyn uppdaterades senast 2023-06-27.</i>
    </Container>
  );
};

export default GDPR;
