import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { FaCopyright, FaHashtag, FaLock, FaShieldAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './../App.css';

const Footer = () => {
  return (
    <footer className="py-4 bg-secondary">
    <Container className="d-flex justify-content-center align-items-center flex-column flex-lg-row">
      <div className="mb-3 text-white "> {/* Applying text-center class to center the div */}
        <FaShieldAlt/> <Link style={{ color: '#fff' }} to="/gdpr">GDPR policy och användning av cookies</Link> <br />
        <FaCopyright/> 2024 Umehem Fastigheter AB<br />
        <FaHashtag title="Organisationsnummer"/> Orgnr: 556701-2470 <br />
        <FaLock /> <Link style={{ color: '#fff' }} to="/control">Adminpanelen</Link> <br />
      </div>
    </Container>
  </footer>
  );
}

export default Footer;
