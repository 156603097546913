import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import * as ApiService from '../../../services/intresseanmalan/user/ApiService';
import EntryModal from './Entry';
import ModalComponent from '../../modal/ModalComponent';
import Overview from './Overview';

const Intresseanmalan = ({ onClose, onLogout }) => {
  const [showModal, setShowModal] = useState(true);
  // state
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);

  const tableLagenhet = "lagenhet";
  const tableLabelLagenhet = "lägenhet"
  const tableLokal = "lokal";
  const tableLabelLokal = "kontor/butik/lokal"

  // LOKAL items
  const
    { data: itemsLokal = []
      , isLoading: isLoadingLokal
      , isError: isErrorLokal
      , error: errorLokal
    } = useQuery(
      ['items', tableLokal],
      async () => {
        const response = await ApiService.getItems(tableLokal);
        return response.data;
      }
    );

  // LGH items
  const
    { data: itemsLgh = []
      , isLoading: isLoadingLgh
      , isError: isErrorLgh
      , error: errorLgh
    } = useQuery(
      ['items', tableLagenhet],
      async () => {
        const response = await ApiService.getItems(tableLagenhet);
        return response.data;
      }
    );

  const queryClient = useQueryClient();

  const deleteItemMutation = useMutation(
    ({ table, id }) => {
      return ApiService.deleteItem(table, id);
      // make sure to return, to await the result
    },
    {
      onSuccess: (data, { table }) => {
        queryClient.invalidateQueries(['items', table]);
      },
    }
  );

  // handles
  const handleDelete = async (table, id) => {
    await deleteItemMutation.mutateAsync({ table, id });
  };

  // render
  const openEntryModal = (table, item = null) => {
    setSelectedTable(table);
    setSelectedItem(item);
    setShowModal(true);
  };

  const renderModalContent = () => {
    return (
      <>
        {isLoadingLgh ? (
          <p>Loading...</p>
        ) : isErrorLgh ? (
          <p>Error: {errorLgh.message}</p>
        ) : (
          <>
            <Overview
              items={itemsLgh}
              onEdit={(item) => openEntryModal(tableLagenhet, item)}
              onDelete={(id) => handleDelete(tableLagenhet, id)}
              tableLabel={tableLabelLagenhet}
              table={tableLagenhet}
            />
          </>
        )}

        {/* Lokal */}
        {isLoadingLokal ? (
          <p>Loading...</p>
        ) : isErrorLokal ? (
          <p>Error: {errorLokal.message}</p>
        ) : (
          <>
            <Overview
              items={itemsLokal}
              onEdit={(item) => openEntryModal(tableLokal, item)}
              onDelete={(id) => handleDelete(tableLokal, id)}
              tableLabel={tableLabelLokal}
              table={tableLokal}
            />
          </>
        )}
      </>
    );
  };

  if (selectedItem) {
    const tableLabel = selectedTable === "lokal" ? tableLabelLokal : tableLabelLagenhet
    // entry
    return (
      <ModalComponent
        title={`Intresseanmälan för ${tableLabel}`}
        onHide={() => setSelectedItem(null)}
        show={showModal}
      >
        <EntryModal
          initialValues={selectedItem}
          onClose={() => setSelectedItem(null)}
          table={selectedTable}
          tableLabel={tableLabel}
        />
      </ModalComponent>
    );
  } else {
    // overview
    return (
      <ModalComponent
        title={"Hantera mina intresseanmälan"}
        onLogout={onLogout}
        onHide={() => { setShowModal(false); onClose(); }}
        show={showModal}
      >
        {renderModalContent()}
      </ModalComponent>
    );
  }
};

export default Intresseanmalan;
