import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid, iconFormatter, textFilterDefault } from './Grid';
import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import { textFilter } from 'react-bootstrap-table2-filter';
import { faBoxArchive, faBuildingUser, faCalendarDay, faComment, faDollarSign, faHashtag, faMoneyBillTransfer, faLayerGroup, faUserClock, faUserGear, faUserPen, faUserTag, faThumbTack } from '@fortawesome/free-solid-svg-icons';
import styles from '../../../AppAdmin.module.css';

const Lokal = ({ match }) =>
  <Grid
    table="intresseanmalan_lokal"
    buttonBackgroundColor="#3A833A"
    match={match}
    columns={columns}
    expandRow={expandRow}
    backgroundClass={styles.greenBackground}
  />
  ;

export default Lokal;

const columns = hideColumns =>
  [
    {
      dataField: "ID",
      text: "id",
      sort: true,
      editable: false,
      filter: textFilterDefault,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        a = Number(a); // Converting into number
        b = Number(b); // Converting into number
        return (order === "asc") ? (a - b) : (b - a);
      },
      headerFormatter: iconFormatter(faHashtag),
      style: () => {
        return {
          width: "60px"
        };
      }
    },
    {
      dataField: "datum",
      text: "datum",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.DATE
      },
      headerFormatter: iconFormatter(faCalendarDay),
      style: () => {
        return {
          width: "100px"
        };
      }
    },
    {
      dataField: "inflyttningsdatum",
      text: "fr.o.m.",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.DATE
      },
      headerFormatter: iconFormatter(faUserClock),
      style: () => {
        return {
          width: "60px"
        };
      }
    },
    {
      dataField: "namn",
      text: "namn",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faUserPen),
      style: () => {
        return {
          width: "100px"
        };
      }
    },
    {
      dataField: "orgnr",
      text: "orgnr",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' ',
      }),
      headerFormatter: iconFormatter(faUserTag),
      style: () => {
        return {
          width: "100px"
        };
      }
    },
    {
      dataField: "gatuadress",
      text: "adress",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      style: () => {
        return { width: "75px" };
      }
    },
    {
      dataField: "postadress",
      text: "postadress",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      style: () => {
        return { width: "75" };
      }
    },
    {
      dataField: "epost",
      text: "epost",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      style: () => {
        return { width: "100px" };
      }
    },
    {
      dataField: "tel",
      text: "tel",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      style: () => {
        return { width: "100px" };
      }
    },
    {
      dataField: "telalt",
      text: "mobil",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      style: () => {
        return { width: "100px" };
      }
    },
    {
      dataField: "verksamhet",
      text: "verksamhet",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faUserGear),
      style: () => {
        return {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          maxWidth: "200px",
          width: "200px"
        };
      }
    },
    {
      dataField: "maxhyra",
      text: "maxhyra",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '1000', label: '1,000' },
          { value: '2000', label: '2,000' },
          { value: '3000', label: '3,000' },
          { value: '4000', label: '4,000' },
          { value: '5000', label: '5,000' },
          { value: '6000', label: '6,000' },
          { value: '7000', label: '7,000' },
          { value: '8000', label: '8,000' },
          { value: '9000', label: '9,000' },
          { value: '10000', label: '10,000' },
          { value: '11000', label: '11,000' },
          { value: '12000', label: '12,000' },
          { value: '13000', label: '13,000' },
          { value: '14000', label: '14,000' },
          { value: '>15000', label: '>15,000' }
        ]
      },
      hidden: hideColumns,
      headerFormatter: iconFormatter(faDollarSign),
      style: () => {
        return { width: "50px" };
      }
    },
    {
      dataField: "bolagstyp",
      text: "bolagstyp",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faBuildingUser),
      style: () => {
        return { width: "75px" };
      }
    },
    {
      dataField: "omsattning",
      text: "omsättning",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faMoneyBillTransfer),
      style: () => {
        return { width: "75px" };
      }
    },
    {
      dataField: "storlek",
      text: "storlek",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faLayerGroup),
      style: () => {
        return { width: "75px" };
      }
    },
    {
      dataField: "onskemal",
      text: "önskemål",
      sort: true,
      hidden: hideColumns,
      filter: textFilterDefault,
      style: () => {
        return { width: "150px" };
      }
    },

    {
      dataField: "code1_dec",
      text: "intern sansvarig",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5', label: '5' }
        ]
      },
      formatter: (value, row) => {
        if (value === '1') {
          return (
            <span style={{ backgroundColor: '#236e96', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        } else if (value === '2') {
          return (
            <span style={{ backgroundColor: '#15b2d3', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        } else if (value === '3') {
          return (
            <span style={{ backgroundColor: '#ffd700', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        } else if (value === '4') {
          return (
            <span style={{ backgroundColor: '#f3872f', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        } else if (value === '5') {
          return (
            <span style={{ backgroundColor: '#ff598f', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        }
      },
      headerFormatter: iconFormatter(faThumbTack),
      style: () => {
        return { width: "30px" };
      }
    },
    {
      dataField: "code2_dec",
      text: "interna anteckningar",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faComment),
      style: () => {
        return {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          maxWidth: "150px",
          width: "150px"
        };
      }
    },
    {
      dataField: "arkiv",
      text: "flytta till aktuella (0), parkerade (1) eller arkvierade (2)",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '0', label: '0:aktivera' },
          { value: '1', label: '1:parkera' },
          { value: '2', label: '2:arkivera' }
        ]
      },
      headerFormatter: iconFormatter(faBoxArchive),
      style: () => {
        return { width: "30px" };
      }
    },
    {
      dataField: "lokalID",
      text: "lokalID",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      style: () => {
        return { width: "30px" };
      }
    }
    // `kreditupplysning` varchar(30) DEFAULT NULL,
    // `kreditcheck` varchar(30) DEFAULT NULL,
    // `flag1` smallint(6) DEFAULT NULL,
  ];

const expandRow = {
  renderer: (row, rowIndex) => {
    return (
      <div style={{ background: '#f6f6f6' }}>
        <table style={{ marginLeft: "30px" }}>
          <tbody>
            <tr>
              <th>
                telefon
              </th>
              <td>
                {row['tel']}{row['telalt'] ? ", " + row['telalt'] : ""}
              </td>
            </tr>
            <tr>
              <th>
                address
              </th>
              <td>
                {row['gatuadress']}, {row['postadress']}
              </td>
            </tr>
            <tr>
              <th>
                epost
              </th>
              <td>
                {row['epost']}
              </td>
            </tr>
            <tr>
              <th>
                maxhyra
              </th>
              <td>
                {row['maxhyra']}
              </td>
            </tr>
            <tr>
              <th style={{ paddingRight: "30px" }}>
                önskemål
              </th>
              <td>
                {row['onskemal']}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
};
