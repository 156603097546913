import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card, Col, Row} from 'react-bootstrap';
import {decode} from 'html-entities';
import realEstates from "../resources/realEstates.json";
import { Carousel } from 'react-bootstrap';

const styles = {
  cardText: {
    // backgroundColor: '#B7E0F2',
    // borderRadius: 55,
    // padding: '3rem'
  },
  cardImage: {
    objectFit: 'cover',
    // borderRadius: 55,
    //width: '18rem',
    height: '30vh'
  }
}

const RealEstates = ({onMarkerSelect}) => {
  const handleMarkerSelect = (markerId) => {
    onMarkerSelect(markerId);
  };

  const nCols = 3;
  return (
    <Row xs={2} md={nCols}>
      { realEstates.map(realEstate => {
        return (
          <Col key={realEstate.idx} className="col-md-4 g-2">
            <Card className="h-100">
              {/* <Card.Img
                style={styles.cardImage}
                variant="top"
                src={process.env.PUBLIC_URL + "images/fastigheter/" + realEstate.id + "/" + realEstate.cardImage}
              /> */}
              <Carousel interval={null}>
              { realEstate.carousel.map(post =>
                <Carousel.Item key={post}>
                  <img
                    style={styles.cardImage}
                    className="d-block w-100" // h-20
                    src={process.env.PUBLIC_URL + "/images/fastigheter/" + realEstate.id + "/" + post}
                    alt="First slide"
                  />
                  {/*
                  <Carousel.Caption>
                  <h1>{realEstate.id}</h1>
                  <h2>{realEstate.idx}</h2>
                  </Carousel.Caption>
                  */}
                </Carousel.Item>
              )}
              </Carousel>
              <Card.Body>
                <div onClick={() => handleMarkerSelect(realEstate.idx)}>{"<<" + realEstate.idx + ">>"}</div>
                <Card.Title>
                {/* markerData.idx */}
                  {decode(realEstate.name)}
                  <Card.Subtitle className="mt-2 text-muted">
                    {decode(realEstate.address)}
                  </Card.Subtitle>
                </Card.Title>
                <Card.Text className="mb-0">
                  <span>
                    <strong>Lokalyta:</strong> {realEstate.m2Lok}<br />
                    <strong>Lägenhetsyta:</strong> {realEstate.m2Lgh}<br />
                    <strong>Typ:</strong> {decode(realEstate.type)}<br />
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )
        })
      }
    </Row>
  )
};

export default RealEstates;
