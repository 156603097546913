import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { Button } from 'react-bootstrap';
import { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { textFilter } from 'react-bootstrap-table2-filter';
import axios from 'axios';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory from 'react-bootstrap-table2-filter'; // , { textFilter }
import React, { useEffect, useCallback, useState } from 'react';
import ReactBootstrapTable from 'react-bootstrap-table-next';
import { FaListUl } from 'react-icons/fa';

axios.defaults.withCredentials = true;

export const iconAdd = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus" viewBox="0 0 16 16"><path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" /><path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" /></svg>
export const iconCollapse = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>;
export const iconExpand = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>;
export const iconTrash = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path><path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path></svg>

const intresseanmalanAdminURL = process.env.REACT_APP_BACKEND_API_URL + "/intresseanmalan/admin/";

const GlobalStyle = createGlobalStyle`
  .selection-column-header, .selection-cell {
    width: 30px !important;
  }
`

const rowStyle = (row, rowIndex) => {
  return {
    backgroundColor: 'white'
  };
};

const headers = {
  headers: { 'Content-Type': 'application/json' }
};

export const textFilterDefault = textFilter({
  style: {
    backgroundColor: 'white'
  },
  placeholder: ' '
})

export const iconFormatter = (faIcon) => (column, colIndex, { sortElement, filterElement }) => {
  const titleTooltip = (
    <Tooltip id={`tooltip-${colIndex}`}>{column.text}</Tooltip>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <OverlayTrigger placement="top" overlay={titleTooltip}>
        <FontAwesomeIcon style={{ paddingBottom: "5px" }} icon={faIcon} />
      </OverlayTrigger>
      {filterElement}
      {sortElement}
    </div>
  );
};

export const Grid = ({ table, match, columns, expandRow, backgroundClass }) => {
  const [buttonIcon, setButtonIcon] = useState(iconCollapse);
  const [comment, setComment] = useState("");
  const [entries, setEntries] = useState([]);
  const [hideColumns, setHideColumns] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  // Calculate the total number of entries
  const totalEntries = entries.length;

  const handleSaveCell = (oldValue, newValue, row, col) => {
    console.log(`Saved ${col.dataField}: ${row.ID} / ${oldValue} -> ${newValue}`);

    const obj = {
      table: table,
      id: row.ID,
      cell_name: col.dataField,
      cell_value: newValue
    };

    axios
      .post(intresseanmalanAdminURL + 'cellUpdate.php', obj, headers)
      .then(response => {
        console.log(response.data);
        console.log('Updated');
      });
  };

  const cellEdit = cellEditFactory({
    mode: 'dbclick',
    afterSaveCell: handleSaveCell
  });

  const getEntries = useCallback(() => {
    const obj = {
      table: table,
      arkiv: match.params.arkiv
    };
    axios
      .post(intresseanmalanAdminURL + 'fetchEntries.php', obj, headers)
      .then(response => {
        console.log(JSON.stringify(response));
        setEntries(response.data);
      })
      .catch((error) => console.log("failed to fetch data", error));
  }, [table, match.params.arkiv]);

  useEffect(() => {
    getEntries();
  }, [getEntries]);

  const toggleCheckbox = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row.ID]);
    } else {
      setSelectedRows(selectedRows.filter(id => id !== row.ID));
    }
  };

  const onChangeHideColumns = () => {
    setHideColumns(!hideColumns);
    setButtonIcon(hideColumns ? iconExpand : iconCollapse);
    setComment(hideColumns ? "Expanded view" : "");
  };

  const createNew = () => {
    const obj = {
      table: table,
      arkiv: match.params.arkiv
    };
    axios.post(`${intresseanmalanAdminURL}createNew.php`, obj, headers)
      .then(response => {
        setComment(response.data);
        getEntries();
      })
      .catch(err => console.log(err));
  };

  const deleteSelected = () => {
    if (selectedRows.length === 0) {
      setComment("Nothing was deleted");
    } else {
      const obj = {
        table: table,
        arkiv: match.params.arkiv,
        selectedRows
      };
      axios.post(
        `${intresseanmalanAdminURL}deleteSelected.php`,
        obj,
        headers
      )
        .then(response => {
          setComment(response.data);
          setSelectedRows([]);
          getEntries();
        })
        .catch(err => console.log(err));
    }
  };

  return (
    <div style={{ margin: "0 auto" }}>
      <div className="mb-2" >
        <Button title="arkivera" className={`btn mx-2 bs-table-border-color ${backgroundClass}`} onClick={deleteSelected} >
          {iconTrash}
        </Button>
        <Button title="skapa" className={`btn mx-1 bs-table-border-color ${backgroundClass}`} onClick={createNew} >
          {iconAdd}
        </Button>
        <Button title="expandera" className={`btn mx-2 bs-table-border-color ${backgroundClass}`} onClick={onChangeHideColumns} >
          {buttonIcon}
        </Button>
        <span className="text-primary font-weight-bold">
          <FaListUl className="mb-1" title="Totalt antal" /> {totalEntries}
        </span>
        &nbsp;
        {comment &&
          <span
            style={{ marginLeft: 10 }}
            id="comment"
            className="alert alert-info fade show"
            role="alert"
          >
            {comment}
          </span>
        }
      </div>
      <div style={{width: "100%"}} className="px-2">
        <React.Fragment>
          <GlobalStyle />
          <ReactBootstrapTable
            selectRow={{
              mode: 'checkbox',
              clickToExpand: true,
              style: (row, rowIndex) => { return { background: 'red' }; },
              onSelect: toggleCheckbox,
              onSelectAll: (isSelected, rows) => {
                setSelectedRows(isSelected ? rows.map(row => row.ID) : []);
              }
            }}
            useRowSelect
            expandRow={expandRow}
            keyField="ID"
            data={entries}
            columns={columns(hideColumns)}
            rowStyle={rowStyle}
            striped
            bordered
            hover
            cellEdit={cellEdit}
            filter={filterFactory()}
            headerClasses={backgroundClass}
          />
        </React.Fragment>
      </div>
    </div>
  );
};

export default Grid;
