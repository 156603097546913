import '../../../App.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { format } from 'date-fns';
import { useUser } from '../../../contexts/UserContext';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SubGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FieldContainer = styled.div`
  flex-basis: ${props => props.basis}%;
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    flex-basis: 100%;
    margin-right: 0;
  }
`;

const EntryForm = ({ register, formStructure, handleSubmit, submitHandler, errors, control, isNew }) => {

  const [user] = useUser();
  const [groupIsToggled, setGroupIsToggled] = useState({});

  useEffect(() => {
    setGroupIsToggled(
      formStructure
        ? Object.keys(formStructure).reduce((acc, group) => {
          return {
            ...acc,
            [group]: true
          };
        }, {})
        : {}
    );
  }, [formStructure]);

  if (!formStructure) {
    // Render a loading state while the form structure is being loaded
    return <div>Loading...</div>;
  } else {
    // console.log(JSON.stringify(groupIsToggled));
  }

  // Default call backs
  var callBacks = {
    getToday: function () {
      return new Date();
    },
    getUserEmail: function () {
      return user.email;
    }
  };

  const toggleGroup = (group) => {
    setGroupIsToggled((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <GroupContainer>
        {Object.entries(formStructure).map(([group, details]) => {
          const colSize = details.columns;
          const fields = Object.entries(details.fields);
          const subgroups = fields.reduce((subgroups, field, index) => {
            if (index % colSize === 0) {
              subgroups.push([]);
            }
            subgroups[subgroups.length - 1].push(field);
            return subgroups;
          }, []);
          return (
            <GroupContainer key={group}>
              {details?.htmlContent && <div className="pb-3  form-input-text" dangerouslySetInnerHTML={{ __html: details?.htmlContent }}></div>}
              {(details?.togglable ?? false) &&
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="ml-auto"
                  onClick={() => toggleGroup(group)}
                >
                  {groupIsToggled[group] ? 'Visa medsökande' : 'Dölj medsökande'}
                </Button>
              }
              {subgroups.map((subgroup, index) => (
                <>
                  <SubGroupContainer key={index}>
                    {subgroup.map(([key, item]) => {
                      let itemIsTogglable = item?.toggle ?? false;
                      if (!itemIsTogglable || (itemIsTogglable && !groupIsToggled[group])) {
                        // destructuring rec
                        const clientOptions = isNew ? item?.client?.create : item?.client?.update;
                        let { hide = true, disabled = false, defaultOptions = {} } = clientOptions ?? {};
                        let { source = null, lambda = null, value = null } = defaultOptions;
                        var defaultValue = null;
                        if (source === "Callback") {
                          if (callBacks.hasOwnProperty(lambda)) {
                            defaultValue = callBacks[lambda]();
                          } else {
                            console.error("ERROR: no callback function exists for " + lambda + ".");
                          }
                        } else if (source === "Constant") {
                          if (value) {
                            defaultValue = value;
                          } else {
                            console.error("ERROR: no default value exists for " + key + ".");
                          }
                        }
                        if (hide) {
                          return (<></>);
                        } else {
                          var validateObj = disabled ? {} : {
                            ...(item?.server?.api?.validate?.required ? { required: item.server?.api?.validate.required } : {}),
                            ...(item?.server?.api?.validate?.pattern ? {
                              pattern: {
                                value: new RegExp(item.server.api.validate.pattern.regexp)
                                , message: item.server.api.validate.pattern.errorMessage
                              }
                            } : {}),
                            ...(item?.server?.api?.validate?.maxLength ? { maxLength: item.server.api.validate.maxLength } : {}),
                            validate: (value) => {
                              var errorMessage = null;
                              var isSuccessful = true;
                              // No need to validate Select, Checkbox, Checkboxes, and radio buttons
                              // Require is checked
                              // Text string

                              // Email check
                              if (item?.server?.api?.validate?.email) {
                                if (value === null) {
                                  console.debug("email value was null");
                                } else if (typeof value === 'string') {
                                  if (!/^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})?$/i.test(value)) {
                                    errorMessage = (errorMessage ?? "") + item.server.api.validate.email;
                                    isSuccessful = false;
                                  } else if (value === "") {
                                    console.debug("email value was the empty string");
                                  } else {
                                    errorMessage = (errorMessage ?? "") + "Email must match regexp or be empty. ";
                                  }
                                } else {
                                  errorMessage = (errorMessage ?? "") + "Email must be a string or null. ";
                                }
                              }

                              // Value check
                              if (item?.server?.api?.validate?.value) {
                                let validate = item.server.api.validate;
                                var valueErrorMessage = null;
                                if (!validate.value.errorMessage) {
                                  console.error("No errorMessage specified for value check for " + key);
                                  valueErrorMessage = "Error: wrong value";
                                } else {
                                  valueErrorMessage = validate.value.errorMessage;
                                }
                                if (validate.value.min ? value < validate.value.min : false) {
                                  errorMessage = (errorMessage ?? "") + valueErrorMessage;
                                  isSuccessful = false;
                                }
                                if (validate.value.max ? value > validate.value.max : false) {
                                  errorMessage = (errorMessage ?? "") + valueErrorMessage;
                                  isSuccessful = false;
                                }
                              }

                              return isSuccessful || errorMessage;
                            }
                          };

                          return (
                            <FieldContainer basis={100}>
                              <Form.Group className="mb-2" controlId={"formItem-" + key} key={key}>
                                <Form.Label className="mb-0 form-label-text" style={{ paddingRight: '10px' }}>
                                  {item?.client?.input?.label} {item?.server?.api?.validate?.required && <span style={{ color: 'red' }}>*</span>}
                                </Form.Label>
                                {item?.client?.input?.as === 'date' ? (
                                  // DATEPICKER
                                  <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                                    <Controller
                                      control={control} // module parameter
                                      name={key}
                                      rules={validateObj}
                                      render={({ field: { onChange, value } }) => (
                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                          <DatePicker
                                            selected={value ? new Date(value) : defaultValue}
                                            onChange={date => {
                                              let dateFormat = item.client.input?.options?.dateFormat ?? "yyyy-MM-dd";
                                              let formattedDate = format(date, dateFormat);
                                              onChange(formattedDate);
                                            }}
                                            {...item.client.input?.options}
                                            disabled={disabled}
                                            className={`form-control form-input-text`}
                                            style={{ flexGrow: 1 }} // Add this
                                          />
                                          {!disabled && <button className={`form-input-text`} onClick={(e) => { e.preventDefault(); onChange(""); }} style={{ color: 'black', borderColor: 'darkgray', borderRadius: '5px', visibility: disabled ? 'hidden' : 'visible' }}>x</button>}
                                        </div>
                                      )
                                      }
                                    />
                                  </div>
                                ) : item?.client?.input?.as === 'checkbox' ? (
                                  // CHECKBOX
                                  <Form.Check
                                    {...register(key, validateObj)}
                                    isInvalid={!!errors[key]}
                                    className={`form-input-text ${errors[key] ? 'is-invalid' : ''}`}
                                  />
                                ) : item?.client?.input?.as === 'checkboxes' ? (
                                  // GROUP OF CHECKBOXES
                                  <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                                    {item.client.input.options.map((option) => {
                                      return (
                                        <Form.Check
                                          {...register(`${key}`, validateObj)} // Register a single form field for the entire group of checkboxes
                                          value={option.value} // Set the value of the checkbox to the option value
                                          key={option.id}
                                          label={option.label}
                                          disabled={disabled}
                                          isInvalid={!!errors[key]}
                                          className={`${item.client.input?.type === 'textarea' ? 'form-textarea' : 'form-input-text'}`}
                                        />
                                      );
                                    })}
                                    {errors[key] && <div className="pt-0 invalid-feedback d-block">{errors[key]?.message}</div>}
                                  </div>
                                ) : item?.client?.input?.as === 'select' ? (
                                  // SELECT
                                  <Form.Control
                                    as="select"
                                    name={key}
                                    {...register(key, validateObj)}
                                    disabled={disabled}
                                    isInvalid={!!errors[key]}
                                    className={`form-input-text`}
                                  >
                                    <option hidden value=""></option>
                                    {item.client.input.options.map((option) => (
                                      <option
                                        className="pr-2"
                                        key={option.id}
                                        value={option.value}
                                        {...(option?.hidden ? { hidden: true } : {})}
                                      >
                                        {`${option.label}\u00A0\u00A0\u00A0`}
                                      </option>
                                    ))}
                                  </Form.Control>
                                ) : item?.client?.input ? (
                                  // INPUT
                                  <Form.Control
                                    as={item.client.input?.as}
                                    type={item.client.input?.type}
                                    {...register(key, validateObj)}
                                    {...(item.client.input?.step) ? { step: item.client.input?.step } : {}}
                                    name={key}
                                    {...(item.client.input?.type === 'textarea' ? { rows: 4 } : {})}
                                    {...(defaultValue && { defaultValue: defaultValue })}
                                    {...(item.client.input?.placeholder) ? { placeholder: item.client.input.placeholder } : {}}
                                    disabled={disabled}
                                    isInvalid={!!errors[key]}
                                    className={`${item.client.input?.type === 'textarea' ? 'form-textarea' : 'form-input-text'} `}
                                    placeholderstyle={{ fontStyle: 'italic' }}
                                  />
                                ) : <></>
                                }
                                <Form.Control.Feedback type="invalid">
                                  {errors[key]?.message}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </FieldContainer>
                          );
                        }
                      } else {
                        return <></>
                      }
                    })}
                  </SubGroupContainer>
                </>
              ))}
            </GroupContainer>
          );
        })}
      </GroupContainer>
      <Button className="mt-2" variant="primary" type="submit">
        Spara
      </Button>
    </Form>
  );
};

export default EntryForm;

/*
  Various input types
  -------------------
  text: A simple text input field.
  email: Similar to text, but includes validate for email addresses.
  password: Similar to text, but hides the input characters.
  number: Similar to text, but only allows numeric input.
  date: A input that provides a UI for date selection.
  datetime-local: A input for entering a date and time, with no time zone.
  month: A input for entering a month and year, with no time zone.
  week: A input for entering a week and year.
  time: A input for entering a time, with no time zone.
  textarea: A multi-line text field.
  file: A file upload field.
  checkbox: A binary choice input (checked/unchecked).
  radio: Part of a group of options where the user can select one.
  range: A slider control to select within a range of numbers.
  select: A dropdown menu to select from a list of options. In React-Bootstrap, this is a separate component (Form.Select) rather than a type of Form.Control.
  color: A control for specifying a color.
  search: A text-field for entering search strings. This can behave a bit differently depending on the browser.
  tel: A field for entering a telephone number.
  url: A field for entering a URL.

  It's important to note that not all of these types are supported by all browsers or all versions
  of browsers. If a browser does not support a particular type, it will usually fall back to being
  a simple text field. Furthermore, even when a type is supported, the browser's UI for the input
  can vary widely.

  The tel input type in HTML5 does not enforce any built-in validation on the format of the phone
  number entered in the field. Its main purpose is to bring up a numeric (telephone-like) keyboard
  on touch devices, thereby facilitating easier input for the user.
*/
