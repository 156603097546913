import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query'; // useQuery
import { useState, useEffect } from 'react';
import * as ApiFormData from '../../../services/intresseanmalan/user/ApiFormData';
import * as ApiService from '../../../services/intresseanmalan/user/ApiService'; //ApiServiceMock
import EntryForm from './EntryForm';

const Entry = ({ initialValues = {}, onClose, table }) => {
  const [error, setError] = useState(null);
  const [formStructure, setFormStructure] = useState(null);
  const [flattenedFormStructure, setFlattenedFormStructure] = useState(null);

  useEffect(() => {
    const loadFormStructure = async (table) => {
      try {
        if (table === "lokal" || table === "lagenhet") {
          const tableEndPoint = `get-form-structure-${table}`
          const formStructure = await ApiFormData.loadFormStructure(tableEndPoint);
          let flattenedFormStructure = {};
          for (let groupKey in formStructure) {
            if (formStructure.hasOwnProperty(groupKey)) {
              const group = formStructure[groupKey];
              for (let field in group.fields) {
                if (group.fields.hasOwnProperty(field)) {
                  flattenedFormStructure[field] = group.fields[field];
                }
              }
            }
          }
          setFormStructure(formStructure);
          setFlattenedFormStructure(flattenedFormStructure);
        } else {
          throw new Error(`Invalid table.`);
        }
      } catch (error) {
        console.error('Error loading form structure:', error);
      }
    };
    loadFormStructure(table);
  }, [table]);

  // form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm({ defaultValues: initialValues, shouldUseNativeValidation: false });

  // using query
  const queryClient = useQueryClient();

  // mutation (with non-optimistic update)
  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(['items', table]);
      onClose();
    },
    onError: (error) => {
      // HTTP status code in the 400-599 range. In your case, the server is returning a 400 status
      // code for validation errors, along with a JSON object containing error details.
      // NOTE: validation failures should be caught by js, so treating those as errors as well.
      console.log("error:" + JSON.stringify(error));
      setError("Failed to validate 2:\n" + error.response.data.error);
    },
    onSettle: () => {
    }
  };

  const createItemMutation = useMutation(
    ({ data }) => { return ApiService.createItem(table, data); },
    mutationOptions,
  );

  const updateItemMutation = useMutation(
    ({ id, data }) => { return ApiService.updateItem(table, id, data); },
    mutationOptions,
  );

  // handles
  const handleCreate = async (data) => {
    await createItemMutation.mutateAsync({ table, data });
  };

  const handleUpdate = async (data) => {
    let id = data.ID;
    await updateItemMutation.mutateAsync({ table, id, data });
  };

  // Utility function
  function convertToApiType(apiType, value) {
    let post;
    if (value === null) {
      throw new Error(`Value to be conveted must not be null)`);
    } else {
      if (apiType === 'int') {
        post = parseInt(Number(value));
        if (isNaN(post)) {
          throw new Error(`Failed to convert '${value}' as ${apiType}`);
        }
      } else if (apiType === 'float') {
        post = parseFloat(Number(value));
        if (isNaN(post)) {
          throw new Error(`Failed to convert '${value}' as ${apiType}`);
        }
      } else if (apiType === 'string') {
        post = String(value);
      } else if (apiType === 'bool') {
        post = Boolean(value);
      } else {
        throw new Error(`Unrecognized type ${apiType}`);
      }
    }
    return post;
  }

  const submitHandler = async (data) => {
    try {
      console.log(`submitHandler`);

      // Convert data to server api type
      // -----------------------------------------
      // Note: when you modify the value in the input field, a string is sent from the form. This is
      // because,in HTML, the value of an input field is always a string, even if the entered value
      // is a number. Hence, even if you type an integer value into the form field, JavaScript will
      // read it as a string.
      // When you submit the form without altering the value, the form handling code detects that
      // the original value is an integer and treats it as such.
      // Hence, we convert all fields to their server api type here.
      let omittedValueKeys = "";
      for (var key in data) {
        console.log("key is " + key);
        if (data.hasOwnProperty(key)) {
          if (data[key] === null || data[key] === undefined) {
            omittedValueKeys = omittedValueKeys + key + ", "
          } else {
            const input = flattenedFormStructure[key]?.client?.input;
            var apiType = flattenedFormStructure[key]?.server?.api?.type;
            let allowConversionTo = [];
            var fieldType = input?.as === "input" ? input?.type : input?.as;
            console.log(fieldType);
            switch (fieldType) {
              case 'checkboxes':
                allowConversionTo = ["[string]", "[int]", "[bool]"];
                break;
              case 'checkbox':
                allowConversionTo = ["string", "int", "bool"];
                break;
              case 'select':
                allowConversionTo = ["string", "int", "bool", "float"];
                break;
              case 'textarea':
                allowConversionTo = ["string"];
                break;
              case 'text':
                allowConversionTo = ["string", "int", "float"];
                break;
              case 'number':
                allowConversionTo = ["string", "int", "bool", "float"];
                break;
              case 'date':
                allowConversionTo = ["string"];
                break;
              case 'email':
                allowConversionTo = ["string"];
                break;
              default:
                throw new Error(`Unable to convert to server API type for ${key}.`);
            }
            if (!allowConversionTo.includes(apiType)) {
              throw new Error(`Incompatible ${input?.as} ${input?.type} field for server api type ${apiType} for ${key}, type must be in ${allowConversionTo}`);
            }
            if (fieldType === 'checkboxes') {
              console.log("value is: " + data[key]);
              if (data[key] === false) {
                data[key] = [];
              }
              if (!Array.isArray(data[key])) {
                // data[key] = [];
              }
              let typeInsideArray = /\[(.*?)\]/.exec(apiType)[1];

              data[key] = data[key].map(value => convertToApiType(typeInsideArray, value));
            } else {
              data[key] = convertToApiType(apiType, data[key]);
            }
            console.log(`   submitHandler> converted ${key} value to a ${apiType} value ${JSON.stringify(data[key])}`);
          }
        }
      }
      if (omittedValueKeys !== "") {
        console.log(`   submitHandler> did not convert fields with null/undefined values: [${omittedValueKeys}]`);
      }

      if ((Object.keys(initialValues).length > 0)) {
        handleUpdate(data);
      } else {
        await handleCreate(data); // Wait for the create operation to complete
      }
      reset(); // Reset the form after the operation
    } catch (error) {
      console.error(`   submitHandler> interpreting ${key} value (from ${fieldType} input) as ${apiType}. ${error}`);
      setError(`Client side interpretation of ${key} value (from ${fieldType} input) as ${apiType}. ${error}`);
    }
  };

  return (
    <>
      <EntryForm
        register={register}
        formStructure={formStructure}
        handleSubmit={handleSubmit}
        submitHandler={submitHandler}
        errors={errors}
        control={control}
        isNew={Object.keys(initialValues).length === 0}
      />
      {error && <div className="mt-3 alert alert-danger" role="alert">{error}</div>}
    </>
  );
};

export default Entry;
