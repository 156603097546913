import React from 'react';
import Card from 'react-bootstrap/Card';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const BlogPost = ({ post }) => {
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('sv-SE', options);
  };

  return (
    <div className="container">
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <span className="mr-auto">{post.title}</span>
          <span className="ml-auto">{formatDate(new Date(post.date))}</span>
        </Card.Header>
        <Card.Body className="d-flex">
          {post.image && (
            <div className="col-md-4">
              <Card.Img
                src={`${API_URL}/news/serve-image.php?image=${post.image}`}
                className="col-m4"
                style={{ flex: '0 0 100px' }}
              />
            </div>
          )}
          <div style={{ margin: '0 0 0 10px' }}>
            <Card.Text>{post.content}</Card.Text>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BlogPost;
