import React from 'react';

const AutomationDisplay = ({ data }) => {
  const entries = data.adminData?.entries ?? [];
  const archived = data.adminData?.archived ?? [];
  return (
    <div className="p-2 mb-1" style={{ backgroundColor: 'black', color: 'lime', width: '700px', borderRadius: '10px', borderWidth: '2px', borderColor: 'lime', borderStyle: 'solid' }}>
      {/* <div>{JSON.stringify(data)}</div> */}
      {<h4>{data.message}</h4>}
      {data.adminData && (
        <>
          <div>
            <div>{data.adminData.nEmailsSent} påminnelser skickade av totalt {data.adminData.totReminders}:</div>
            <table className="mx-3">
              <tbody>
                {Object.values(entries).map((entry, index) => (
                  <tr key={"reminder-" + index}>
                    <td>#{entry.ID}</td>
                    <td>{entry.name}</td>
                    <td>({entry.email})</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="my-2">
            <div>{data.adminData.nArkiverade} arkiveringar:</div>
            <table className="mx-3">
              <tbody>
                {Object.values(archived).map((entry, index) => (
                  <tr key={"archival-" + index}>
                    <td>#{entry.ID}</td>
                    <td>{entry.name}</td>
                    <td>({entry.email})</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default AutomationDisplay;