import { useState } from 'react';
import ModalComponent from './modal/ModalComponent';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HyresInfoModal = ({ onClose }) => {
  const [showModal, setShowModal] = useState(true);

  const renderModalContent = () => {
    return (
      <>
        <div>
          <h2>Blanketter</h2>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} />
            <a target="_blank" rel="noopener noreferrer" href="blanketter/andrahand.pdf">
              Ansökan om andrahandsuthyrning
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} />
            <a target="_blank" rel="noopener noreferrer" href="blanketter/andrahandskontrakt.pdf">
              Kontrakt för andrahandsuthyrning (observera att ansökan om andrahandsuthyrning
              först måste godkännas)
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} />
            <a target="_blank" rel="noopener noreferrer" href="blanketter/byte.pdf">
              Ansökan om lägenhetsbyte
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} />
            <a target="_blank" rel="noopener noreferrer" href="blanketter/uppsagning.pdf">
              Uppsägning av lägenhet, garage eller bilplats
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} />
            <a target="_blank" rel="noopener noreferrer" href="blanketter/flyttstadning.pdf">
              Flyttstädningschecklista
            </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '10px' }} />
            <a target="_blank" rel="noopener noreferrer" href="blanketter/storning.pdf">
              Störningsanmälan
            </a>
          </div>
        </div>

        <div>
          <h2>Flyttanmälan till Skatteverket</h2>

          När du flyttar ska du göra en flyttanmälan till Skatteverket
          (<a href="https://skatteverket.se/flytta" target="_blank">https://skatteverket.se/flytta</a>)
          så att du får rätt folkbokföringsadress. Gör din anmälan i
          förväg eller senast en vecka efter flytten. Skatteverket sprider din nya adress till
          samtliga myndigheter och även till många företag.  Vill du ha eftersändning av din post får
          du beställa det hos Svensk adressändring (www.adressandring.se).

          <br /><br />

          Du som bor i en lägenhet ska vara folkbokförd på lägenheten. Lägenhetsnumret är en del av
          din adress och består av fyra siffror som visar på vilken våning och var på våningen
          lägenheten finns. Du kan hitta ditt lägenhetsnummer i ditt kontrakt eller på ditt postfack
          eller ytterdörr.

          <br /><br />

          Du som är inneboende eller hyr lägenhet i andra hand ska inte ange c/o i din flyttanmälan.
          Du ska se till att ditt namn finns på dörren eller på postboxen i entrén om det finns en
          sådan.
        </div>
      </>
    );
  }

  return (
    <ModalComponent
      title={"Information till våra hyresgäster"}
      onHide={() => { setShowModal(false); onClose() }}
      show={showModal}
    >
      {renderModalContent()}
    </ModalComponent>
  );
};

export default HyresInfoModal;
