import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import BlogPost from '../components/BlogPost';
import React, { useState, useEffect } from 'react';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Blog = () => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [archivePosts, setArchivePosts] = useState([]);

  useEffect(() => {
    axios.get(`${API_URL + "/news/posts"}`)
      .then(response => {
        const news = response.data.filter(post => post.show && !post.archive);
        const archive = response.data.filter(post => post.show && post.archive);
        setNewsPosts(news);
        setArchivePosts(archive);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  return (
    <Container>
      <Row id="news" className="px-4 my-4">
        <h2>Aktuellt</h2>
        <div className="news-section px-0 mx-0">
          {newsPosts.map((post, i) => <BlogPost key={i} post={post} />)}
        </div>
      </Row>
      <Row id="news" className="px-4 mb-5">
        <h2>Arkiv</h2>
        <div className="archive-section px-0 mx-0">
          {archivePosts.map((post, i) => <BlogPost key={i} post={post} />)}
        </div>
      </Row>
    </Container >
  );
};

export default Blog;
