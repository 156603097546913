import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Row } from 'react-bootstrap';
import { useState } from 'react';
import LedigaLokaler from '../components/LedigaLokaler';
import ModalComponent from './modal/ModalComponent';
import React from 'react';

const LedigtModal = ({ onClose, setActiveModal }) => {
  const [showModal, setShowModal] = useState(true);
  const renderModalContent = () => {
    return (
      <Row id="ledigt" className="px-2 my-2" >
        <p>
          <strong>Söker du kontor, butik eller industrilokal?</strong><br />
          Nedan följer en lista med våra lediga objekt.
          Vid intresse,
          vänligen <Button onClick={() => setActiveModal('kontakt-link')}>kontakta oss</Button> eller
          fyll i en <Button onClick={() => setActiveModal('intresseanmalan-link')}>intresseanmälan</Button>.
        </p>
        <p>
          <strong>Söker du en lägenhet?</strong><br />
          Notera att vi inte annonserar lediga lägenheter, dessa tilldelas till intressenter som
          fyllt i vår <Button onClick={() => setActiveModal('intresseanmalan-link')}>intresseanmälan</Button>.
        </p>
        <LedigaLokaler id="ledigt" />
      </Row >
    );
  };

  return (
    <ModalComponent
      title={"Lediga kontor/butiker/lokaler"}
      onHide={() => { setShowModal(false); onClose() }}
      show={showModal}
    >
      {renderModalContent()}
    </ModalComponent>
  );
};

export default LedigtModal;
