import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import RealEstates from '../components/RealEstates';
import MyMap from '../components/MyMap';
import React, { useState } from 'react';

const Fastigheter = () => {
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const mapRef = React.createRef();

  // Handle marker selection
  const handleMarkerSelect = (markerId) => {
    setSelectedMarkerId(markerId);
    mapRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Container>
      <h2>Fastigheter</h2>
      <Row id="googleMap" className="px-4 my-3">
        <div className="mb-3">
          Här följer en list med fastigheter som vi förvalter:
        </div>
        <div>
          Umehem kontoret <img src={process.env.PUBLIC_URL + "/images/mapicons/yellowBall.png"} alt="Yellow Ball" /> |
          Kontor/Butiker/Lokaler <img src={process.env.PUBLIC_URL + "/images/mapicons/blueBall.png"} alt="Blue Ball" /> |
          Hyresrätter <img src={process.env.PUBLIC_URL + "/images/mapicons/redBall.png"} alt="Red Ball" />
        </div>
        <div ref={mapRef}>
          <MyMap selectedMarkerId={selectedMarkerId} />
        </div>
      </Row>
      <Row id="fastighetsbeteckning" className="px-4 my-5">
        <h2>Fastighetsbeteckning</h2>
        <div>
          Här följer en lista med våra fastigheter.
        </div>
        <RealEstates onMarkerSelect={handleMarkerSelect} />
      </Row>
    </Container>
  );
};

export default Fastigheter;
