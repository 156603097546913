import { Alert, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const LoginVerificationForm = ({ handleSubmit, alert, email }) => {

  const {
    handleSubmit: mainFormHandleSubmit,
    formState: { errors },
    register,
    watch,
    setError
  } = useForm({ shouldUseNativeValidation: false });

  const codeValue = watch('code');  // Watch the "code" field for changes

  useEffect(() => {
    // If the code length reaches 8, auto-submit the form
    if (codeValue && codeValue.length === 8) {
      mainFormHandleSubmit(handleSubmit)();
    }

    // If the entered code contains non-numeric characters, show an error
    if (codeValue && /[^\d]/.test(codeValue)) {
      setError('code', {
        type: "manual",
        message: "Koden måste ha 8 siffror"
      });
    }
    // eslint-disable-next-line
  }, [codeValue]);

  return (
    <Form onSubmit={mainFormHandleSubmit(handleSubmit)}>
      { alert.message &&
        <Alert variant={alert.variant}>
          {alert.message}{alert.count > 0 && ` (#${alert.count})`}
        </Alert>
      }
      <Form.Group controlId="formCode">
        <Form.Control
          type="tel"
          name="code"
          placeholder="Ange den 8-siffriga verifikationskoden"
          maxLength="8"
          {...register('code', {
            required: "Koden måste ha 8 siffror",
            minLength: { value: 8, message: "Koden måste ha 8 siffror"},
            pattern: { value: /^[0-9]{8}$/, message: "Koden måste ha 8 siffror"}
          })}
          isInvalid={!!errors.code}
        />
        <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
      </Form.Group>
      <Button className="mt-2" variant="primary" type="submit">
        Logga in
      </Button>
    </Form>
  );
};

export default LoginVerificationForm;
