import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import axios from 'axios';

axios.defaults.withCredentials = true;

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const styles = {
  cardText: {
  },
  cardImage: {
    objectFit: 'cover',
    height: '30vh'
  }
}

const Projects = () => {
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    axios.get(`${API_URL + "/json/projects"}`)
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, []);

  const nCols = 3;
  return (
    <Row xs={2} md={nCols} >
      {projects && projects.map(post =>
        <Col key={post.id} className="col-md-3 g-2">
          <Card className="h-100">
            {post.video ?
              <div>
                <iframe
                  style={styles.cardImage}
                  className="d-block w-100 h-20"
                  src="https://www.youtube.com/embed/GcAitzDaQwg?start=15"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                  allowFullScreen
                ></iframe>
              </div> :
              <Card.Img style={styles.cardImage} variant="top" src={process.env.PUBLIC_URL + post.image} />
            }
            <Card.Body>
              <Card.Title style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{post.title}</span>
                <span style={{ fontSize: 'small', fontWeight: 'bold', color: 'gray' }}>{post.year}</span>
              </Card.Title>
              <Card.Text style={styles.cardText}>
                {post.description}
              </Card.Text>
              {/* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
          </Card>
        </Col>
      )
      }
    </Row>
  )
};

export default Projects;
