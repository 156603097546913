import { Alert, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const LoginForm = ({ handleSubmit, alert }) => {
  const {
    handleSubmit: mainFormHandleSubmit,
    formState: { errors },
    register,
  } = useForm({ shouldUseNativeValidation: false });

  return (
    <Form onSubmit={mainFormHandleSubmit(handleSubmit)}>
       { alert.message &&
        <Alert variant="danger">
          {alert.message}{alert.count > 0 && ` (#${alert.count})`}
        </Alert>
      }
      <Form.Group controlId="formEmail">
        <Form.Label>Epost</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Ange din epostadress"
          {...register('email', { required: 'Ange epostadress' })}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
      </Form.Group>
      <Button className="mt-2" variant="primary" type="submit">
        Skicka verifieringskod
      </Button>
    </Form>
  );
};

export default LoginForm;
