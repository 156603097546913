 import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';

// contexts
import { AdminProvider } from './contexts/AdminContext';
import { UserProvider } from './contexts/UserContext';

// It's important to note that although the queryClient variable isn't global, the QueryClient
// instance it refers to is effectively global within the context of your React application, if
// you've set up a QueryClientProvider somewhere at the root of your app. This is because the
// QueryClient instance is passed down through React context, which is accessible from any component
// in your application.
const queryClient = new QueryClient();

const appComponent = (
  <QueryClientProvider client={queryClient}>
    <AdminProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </AdminProvider>
    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
);

const wrappedAppComponent = process.env.REACT_APP_ENABLE_STRICT_MODE === 'true' ? (
  <>
  <React.StrictMode>{appComponent}</React.StrictMode>
  {console.log("strict mode")};
  </>
) : (
  appComponent
);

ReactDOM.createRoot(document.getElementById('root')).render(wrappedAppComponent);
