import { Button } from 'react-bootstrap';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Overview = ({ items, onEdit, onDelete, tableLabel, table }) => {

  const downloadCSV = (items, filename) => {
    let fieldNames = Object.keys(items[0]);
    // Helper function to process a value and handle array values with commas
    const processValue = (value) => {
      value = value ?? "";
      if (Array.isArray(value)) {
        return value.map((element) => element.replace(/,/g, ' ')).join(' | ');
      }
      return String(value).replace(/,/g, ' ');
    };
    // Rename a field name
    const fieldNameToReplace = 'kreditupplysning';
    const newFieldName = 'datahanteringssamtycke';
    // CSV
    const csvContent = `data:text/csv;charset=utf-8,${[
      fieldNames.map((fieldName) => fieldName === fieldNameToReplace ? newFieldName : fieldName).join(','),
      ...items.map((item) => fieldNames.map((fieldName) => processValue(item[fieldName])).join(',')),
    ].join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <ul style={{ listStyle: 'none', padding: '0' }}>
      <li>
        <h2>För {tableLabel}</h2>
      </li>
      <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Button variant="primary" onClick={() => onEdit({})} style={{ marginBottom: '10px' }}>
            Ny
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            title="Ladda ner alla intresseanmälan som CSV fil."
            onClick={() => downloadCSV(items, table)}
            style={{ border: '0', backgroundColor: 'transparent', marginLeft: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={faFileArrowDown} color="gray" />
            <span style={{ fontSize: '0.8rem', marginBottom: '0.5rem', color: 'gray' }}>Ladda ner CSV</span>
          </Button>
        </div>
      </li>
      {Array.isArray(items) &&
        items.map((item) => (
          <li key={item.ID} style={{ marginBottom: '10px' }}>
            <Button variant="secondary" onClick={() => onEdit(item)} style={{ marginRight: '5px' }}>
              Ändra
            </Button>
            <Button variant="danger" onClick={() => onDelete(item.ID)}>
              Ta bort
            </Button>
            <span className="p-2">
              { "Intresseanmälan #" + item.ID + " " + item.datum }
            </span>
          </li>
        ))}
    </ul>
  );
};

export default Overview;
