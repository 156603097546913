import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Employees = () => {
  const [employees, setEmployees] = useState(null);

  useEffect(() => {
    axios.get(`${API_URL + "/json/employees"}`)
      .then(response => {
        setEmployees(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, []);

  const nCols = 3;
  return (
    <Row xs={2} md={nCols} className="g-1">
      { employees && employees.map(post =>
          <Col key={post.id} className="col-md-3">
            <Card className="h-100">
              <Card.Body>
                <Card.Title>{post.name}</Card.Title>
                <Card.Text>{post.title}<br />{post.email}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )
      }
    </Row>
  )
}

export default Employees;
