import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useUser } from '../../../contexts/UserContext';
import * as ApiLoginService from '../../../services/authenticateWith/email/ApiLoginService';
import LoginForm from './LoginForm';
import LoginVerificationForm from './LoginVerificationForm';
import ModalComponent from '../../modal/ModalComponent';

const Login = ({ Component, onClose }) => {
  const [showModal, setShowModal] = useState(true);
  const [user, setUser] = useUser();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(null);
  const [alert, setAlert] = useState({ variant: null, message: null, count: 0 });

  // mutations (used to be able to track state of request, e.g., loading, complete, ...)
  const mutationOptions = {
    onSuccess: () => {
      // queryClient.invalidateQueries('login');
      // onClose();
      console.info('LoginModal > osSuccess: succeeded');
    },
    onError: (error) => {
      console.info('LoginModal > onError: ', error.response.data);
      // const current =  error.response.data.message;
      // setAlert(prev => ({
      //   message: current,
      //   count: prev.message === current ? prev.count + 1 : 1,
      // }));
    },
    onSettled: (data, error, variables, context) => {
      // the onSettled callback in the mutation options is called after the mutation is complete,
      // regardless of whether it was successful or encountered an error. It provides a way to
      // perform actions that should be executed after the mutation has settled, such as cleanup
      // tasks or handling the state after the mutation.
      // In the case of optimistic updates, where you update the local state optimistically before
      // the server response, you can use the onSettled callback to roll back the optimistic updates
      // if the mutation encountered an error. For example, if you update a list of items
      // optimistically and the mutation fails, you can revert the local list to its previous state
      // in the onSettled callback.
      console.info('LoginModal > onSettled: settled');
    },
  };

  const checkIsLoggedInMutation = useMutation(
    () => ApiLoginService.checkIsLoggedIn(),
    mutationOptions
  );

  const checkIsLoggedIn = async () => {
    await checkIsLoggedInMutation.mutateAsync()
      .then(response => {
        if (response.data.isLoggedIn) {
          console.log("already authenticated (by email)");
          setUser({ isAuthenticated: true, email: response.data.email });
        } else {
          setUser({ isAuthenticated: false, email: null });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("checking if still logged in");
    if (!user.isAuthenticated) {
      checkIsLoggedIn();
    }
    // eslint-disable-next-line
  }, [user.isAuthenticated]);

  const handleVerificationCodeSendMutation = useMutation(
    ({ data }) => ApiLoginService.verificationCodeSend(data),
    mutationOptions
  );

  const handleVerificationCodeVerifyMutation = useMutation(
    ({ data }) => ApiLoginService.verificationCodeVerify(data),
    mutationOptions
  );

  const handleLogoutMutation = useMutation(
    () => ApiLoginService.logout(),
    mutationOptions
  );

  // Resets the form
  const onReset = () => {
    setEmail(null);
    setStep(1);
    setAlert({ variant: null, message: null, count: 0 });
  };

  // Sends the verification code per email
  const handleVerificationCodeSend = async (data) => {
    const response = await handleVerificationCodeSendMutation.mutateAsync({ data });
    const message = response.data.message;
    if (response.data.emailSent === true) {
      setAlert({ variant: "info", message: message, count: 0 });
      setEmail(data.email);
      setStep(2);
    } else {
      setAlert(prev => ({
        variant: "danger",
        message: message,
        count: prev.message === message ? prev.count + 1 : 1,
      }));
    }
  };

  // Validates the entered verification code
  const handleVerificationCodeVerify = async (data) => {
    const response = await handleVerificationCodeVerifyMutation.mutateAsync({ data });
    const message = response.data.message;
    if (response.data.loggedIn === true) {
      setAlert({ variant: null, message: null, count: 0 });
      setUser(prev => ({ isAuthenticated: true, email: email }));
    } else if (response.data.status === "retry") {
      setAlert(prev => ({
        variant: "danger",
        message: message,
        count: prev.message === message ? prev.count + 1 : 1,
      }));
    } else if (response.data.status === "restart") {
      handleRestart(response.data.message);
    } else {
      handleRestart("Var god börja om, något gick fel vid verifieringen av koden. " + message);
    }
  };

  // Restarts the process
  const handleRestart = async (message) => {
    console.info("handleRestart...");
    const response = await handleLogoutMutation.mutateAsync();
    if (response.data.loggedOut === true) {
      setUser(prev => ({ isAuthenticated: false, email: null }));
      setAlert({ variant: "info", message: message, count: 0 });
      setEmail(null);
      setStep(1);
    } else {
      setAlert({ variant: "danger", message: "Could not logout " + message, count: 0 });
      console.error('Could not logout from the server. ' + message);
    }
  };

  const handleLogout = async () => {
    await handleRestart("Loggade ut.");
  }

  const { isLoading } = checkIsLoggedInMutation;
  if (isLoading) {
    return <div>Loading...</div>;
  } else if (user.isAuthenticated) {
    return <Component onLogout={handleLogout} onClose={onClose}/>;
  } else {
    return (
      <ModalComponent
        title="Logga in"
        onReset={step === 2 ? onReset : undefined}
        onHide={() => { setShowModal(false); onClose(); }}
        show={showModal}
      >
        {step === 1 ? (
          <LoginForm
            handleSubmit={handleVerificationCodeSend}
            alert={alert}
          />
        ) : (
          <LoginVerificationForm
            handleSubmit={handleVerificationCodeVerify}
            alert={alert}
            email={email}
          />
        )}
      </ModalComponent>
    );
  }
};

export default Login;
