import axios from 'axios';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const path = `${API_URL}/intresseanmalan/automation`;

export const triggerReminders = async (data) => {
  return axios
    .post(`${path}/reminders`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
