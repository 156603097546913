import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import React from 'react';

const Hyresinfo = () => {
  return (
    <Container>
      <div>
        <h2>Flyttanmälan till Skatteverket</h2>

        När du flyttar ska du göra en flyttanmälan till Skatteverket
        (https://skatteverket.se/flytta) så att du får rätt folkbokföringsadress. Gör din anmälan i
        förväg eller senast en vecka efter flytten. Skatteverket sprider din nya adress till
        samtliga myndigheter och även till många företag.  Vill du ha eftersändning av din post får
        du beställa det hos Svensk adressändring (www.adressandring.se).

        <br /><br />

        Du som bor i en lägenhet ska vara folkbokförd på lägenheten. Lägenhetsnumret är en del av
        din adress och består av fyra siffror som visar på vilken våning och var på våningen
        lägenheten finns. Du kan hitta ditt lägenhetsnummer i ditt kontrakt eller på ditt postfack
        eller ytterdörr.

        <br /><br />

        Du som är inneboende eller hyr lägenhet i andra hand ska inte ange c/o i din flyttanmälan.
        Du ska se till att ditt namn finns på dörren eller på postboxen i entrén om det finns en
        sådan.
      </div>
    </Container>
  );
};

export default Hyresinfo;
