import axios from 'axios';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const pathToLogin = `${API_URL}/authenticateWith/password`;

// use for checking password for an email
export const passwordVerify = async (data) => {
  return axios
    .post(`${pathToLogin}/login`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

// use for checking password for an email
export const checkIsLoggedIn = async () => {
  return axios
    .get(`${pathToLogin}/check`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const logout = async () => {
  return axios
    .post(`${pathToLogin}/logout`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
