import axios from 'axios';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const intresseanmalanPath = `${API_URL}/intresseanmalan/user`;

export const loadFormStructure = async (name) => {
  try {
    const response = await axios.get(`${intresseanmalanPath + "/" + name}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
