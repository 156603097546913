import axios from 'axios';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const intresseanmalanPath = `${API_URL}/intresseanmalan/user`;

export const createItem = async (table, data) => {
  try {
    console.info(`createItem with ${table} and ${JSON.stringify(data)}...`);
    data.table = table;
    const response = await axios.post(`${intresseanmalanPath}/items`, data);
    console.info(`   ...createItem returned with ${JSON.stringify(response.data)}`);
    return response;
  } catch (error) {
    console.error('Error during createItem:', error);
    throw error;
  }
}

export const getItems = async (table) => {
  try {
    console.info(`getItems with ${table} (as params)...`);
    const response = await axios.get(`${intresseanmalanPath}/items`, { params: { table: table } });
    console.info(`   ...getItems returned with ${JSON.stringify(response.data)}`);
    return response;
  } catch (error) {
    console.error('Error during getItems:', error);
    throw error;
  }
};

export const updateItem = async (table, id, data) => {
  try {
    console.info(`updateItem with table=${table}, id=${id}, data=${JSON.stringify(data)}...`);
    if (!data) {
      throw new Error("Data is undefined in updateItem");
    }
    data.table = table;
    const response = await axios.put(`${intresseanmalanPath}/items/${id}`, data);
    console.info(`   ...updateItem returned with ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    console.error('   ...updateItem ERROR:', error);
    throw error;
  }
};

export const deleteItem = async (table, id) => {
  try {
    console.info(`deleteItem with table=${table} and id=${id}...`);
    const response = await axios.delete(`${intresseanmalanPath}/items/${table}/${id}`);
    console.info(`   ...deleteItem returned with ${JSON.stringify(response.data)}`);
    return response;
  } catch (error) {
    console.error('Error during deleteItem:', error);
    throw error;
  }
}

/*
  The services folder is used to organize the code that handles interactions with external APIs or
  services. In this case, the services folder contains the ApiService.js file, which is responsible
  for managing the HTTP requests to your backend API.

  ApiService.js contains functions that make HTTP requests using Axios, a popular library for
  making HTTP requests in JavaScript. The functions in ApiService.js interact with your backend API
  to perform CRUD (Create, Read, Update, and Delete) operations on the data.

  Here is a brief overview of each function in ApiService.js:

  createItem(data): Sends a POST request to the backend API to create a new item with the provided
  data.

  getItems(): Sends a GET request to the backend API to fetch all the items.

  updateItem(id, data): Sends a PUT request to the backend API to update the item with the given id
  and updated data.

  deleteItem(id): Sends a DELETE request to the backend API to delete the item with the given id.
  These functions are used by the components in your Next.js application to interact with the
  backend API and manage the data.
*/
