import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';

import LedigaLokaler from '../components/LedigaLokaler';
import React from 'react';

const Ledigt = () => {
  return (
    <Container>
      <Row id="ledigt" className="px-4 my-5">
        <h2>Lediga kontor/butiker/lokaler</h2>
        <div>
          På vår hemsida presenterar vi kontinuerligt en uppdaterad översikt över de lokaler som för
          närvarande är lediga. Notera att vi inte annonserar lediga lägenheter separat. Istället
          tilldelas dessa direkt till de intressenter som har registrerat sitt intresse genom att
          fylla i vår intresseanmälan (se länken i menyn) här på webbplatsen. Om du är intresserad
          av dessa lokaler, eller vill få information om framtida lediga lokaler i någon av våra
          fastigheter, är du varmt välkommen att kontakta oss eller anmäla ditt intresse direkt via
          vår webbplats.
        </div>
        <LedigaLokaler id="ledigt" />
      </Row>
    </Container>
  );
};

export default Ledigt;
