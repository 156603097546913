import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Grid, iconFormatter, textFilterDefault} from './Grid';
import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import { textFilter } from 'react-bootstrap-table2-filter';
import { faBaby, faBoxArchive, faCalendarDay, faCar, faComment, faDollarSign, faEllipsis, faHashtag, faLayerGroup, faSmoking, faPaw, faThumbTack, faUserClock, faUsers, faUserPen, faUserTag } from '@fortawesome/free-solid-svg-icons';
import styles from '../../../AppAdmin.module.css';

const Lagenhet = ({ match }) =>
  <Grid
    table="intresseanmalan_lagenhet"
    buttonBackgroundColor="#20809d"
    match={match}
    columns={columns}
    expandRow={expandRow}
    backgroundClass={styles.blueBackground}
  />
  ;

export default Lagenhet;

const columns = hideColumns =>
  [
    {
      dataField: "ID",
      text: "id",
      sort: true,
      editable: false,
      filter: textFilterDefault,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        a = Number(a); // Converting into number
        b = Number(b); // Converting into number
        return (order === "asc") ? (a - b) : (b - a);
      },
      headerFormatter: iconFormatter(faHashtag),
      style: () => {
        return {
          width: "60px"
        };
      }
    },
    {
      dataField: "datum",
      text: "datum",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.DATE
      },
      headerFormatter: iconFormatter(faCalendarDay),
      style: () => {
        return {
          width: "100px"
        };
      }
    },
    {
      dataField: "inflyttningsdatum",
      text: "fr.o.m.",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.DATE,
        width: "auto"
      },
      headerFormatter: iconFormatter(faUserClock),
      style: () => {
        return {
          width: "60px"
        };
      }
    },
    // person 1
    {
      dataField: "namn1",
      text: "namn",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faUserPen),
      style: () => {
        return { width: "200px" };
      },
    },
    {
      dataField: "pnr1",
      text: "personnr.",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' ',
      }),
      headerFormatter: iconFormatter(faUserTag),
      style: () => {
        return {
          width: "85px"
        };
      }
    },
    {
      dataField: "checkbox_storlek",
      text: "rok",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5', label: '5' }
        ]
      },
      headerFormatter: iconFormatter(faLayerGroup),
      style: () => {
        return {
          width: "85px"
        };
      }
    },
    {
      dataField: "maxhyra",
      text: "hyra",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '1000', label: '1,000' },
          { value: '2000', label: '2,000' },
          { value: '3000', label: '3,000' },
          { value: '4000', label: '4,000' },
          { value: '5000', label: '5,000' },
          { value: '6000', label: '6,000' },
          { value: '7000', label: '7,000' },
          { value: '8000', label: '8,000' },
          { value: '9000', label: '9,000' },
          { value: '10000', label: '10,000' },
          { value: '11000', label: '11,000' },
          { value: '12000', label: '12,000' },
          { value: '13000', label: '13,000' },
          { value: '14000', label: '14,000' },
          { value: '>15000', label: '>15,000' }
        ]
      },
      headerFormatter: iconFormatter(faDollarSign),
      style: () => {
        return { width: "30px" };
      },
    },
    {
      dataField: "checkbox_bilplats",
      text: "bil",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: 'parkering', label: 'parkering' },
          { value: 'skärmplats', label: 'skärmplats' },
          { value: 'garage', label: 'garage' }
        ]
      },
      style: () => {
        return { width: "180px" };
      },
      headerFormatter: iconFormatter(faCar)
    },
    {
      dataField: "husdjur",
      text: "husdjur",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.CHECKBOX,
        value: 'Ja:Nej'
      },
      headerFormatter: iconFormatter(faPaw),
      style: () => {
        return { width: "20px" };
      }
    },
    {
      dataField: "husdjurtyp",
      text: "typ av husdjur",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faEllipsis),
      style: () => {
        return {
          width: "100px"
        };
      }
    },
    {
      dataField: "rokare",
      text: "rökare",
      sort: true,
      style: () => {
        return { width: "50px" };
      },
      filter: textFilterDefault,
      editor: {
        type: Type.CHECKBOX,
        value: 'Ja:Nej'
      },
      headerFormatter: iconFormatter(faSmoking)
    },
    {
      dataField: "antalvuxna",
      text: "# vuxna",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5', label: '5' }
        ]
      },
      style: () => {
        return { width: "10px" };
      },
      headerFormatter: iconFormatter(faUsers)
    },
    {
      dataField: "antalbarn",
      text: "# barn",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5', label: '5' },
          { value: '6', label: '6' },
          { value: '7', label: '7' }
        ]
      },
      style: () => {
        return { width: "20px" };
      },
      headerFormatter: iconFormatter(faBaby)
    },
    {
      dataField: "code1_dec",
      text: "intern ansvarig",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5', label: '5' }
        ]
      },
      formatter: (value, row) => {
        if (value === '1') {
          return (
            <span style={{ backgroundColor: '#236e96', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        } else if (value === '2') {
          return (
            <span style={{ backgroundColor: '#15b2d3', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        } else if (value === '3') {
          return (
            <span style={{ backgroundColor: '#ffd700', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        } else if (value === '4') {
          return (
            <span style={{ backgroundColor: '#f3872f', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        } else if (value === '5') {
          return (
            <span style={{ backgroundColor: '#ff598f', height: '100%', width: '100%', display: 'block' }}>&nbsp;{/* value */}</span>
          )
        }
      },
      headerFormatter: iconFormatter(faThumbTack),
      style: () => {
        return { width: "30px" };
      }
    },
    {
      dataField: "code2_dec",
      text: "interna anteckningar",
      sort: true,
      filter: textFilterDefault,
      headerFormatter: iconFormatter(faComment),
      style: () => {
        return { width: "150px" };
      }
    },
    {
      dataField: "arkiv",
      text: "flytta till aktuella (0), parkerade (1) eller arkvierade (2)",
      sort: true,
      filter: textFilterDefault,
      editor: {
        type: Type.SELECT,
        options: [
          { value: '0', label: '0:aktivera' },
          { value: '1', label: '1:parkera' },
          { value: '2', label: '2:arkivera' }
        ]
      },
      style: () => {
        return { width: "20px" };
      },
      headerFormatter: iconFormatter(faBoxArchive)
    },

    // hidden fields
    // person 1
    {
      dataField: "tel1",
      text: "tel1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "tel1alt",
      text: "mobil1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "postadress1",
      text: "postadress1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "gatuadress1",
      text: "adress1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "epost1",
      text: "epost1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "arbetsgivare1",
      text: "arbete1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "sysselsattning1",
      text: "arbete1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "inkomst1",
      text: "inkomst1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "hyresvard1",
      text: "hyresvärd1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "referens1",
      text: "referens1",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      minWidth: 140
    },

    // person 2
    {
      dataField: "namn2",
      text: "namn2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      minWidth: 140
    },
    {
      dataField: "pnr2",
      text: "personnummer2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: true,
      minWidth: 140
    },
    {
      dataField: "tel2",
      text: "tel2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      minWidth: 140
    },
    {
      dataField: "tel2alt",
      text: "mobil2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      minWidth: 140
    },
    {
      dataField: "postadress2",
      text: "postadress2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns,
      minWidth: 140
    },
    {
      dataField: "gatuadress2",
      text: "adress2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "epost2",
      text: "epost2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "arbetsgivare2",
      text: "arbete2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "sysselsattning2",
      text: "arbete",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "inkomst2",
      text: "inkoms2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "hyresvard2",
      text: "hyresvärd2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "referens2",
      text: "referens2",
      sort: true,
      filter: textFilter({
        style: { backgroundColor: 'white' },
        placeholder: ' '
      }),
      hidden: hideColumns
    },
    {
      dataField: "onskemal",
      text: "önskemal",
      sort: true,
      filter: textFilter({
        style: {
          backgroundColor: 'white',
          width: "1000px"
        },
        placeholder: ' '
      }),
      hidden: hideColumns
    }
  ];

function Subgrid({ isFirst, pnr, arbetsgivare, namn, inkomst, epost, sysselsattning, tel, telalt, hyresvard, gatuadress, postadress, referens }) {
  if (!isFirst && (!pnr && !arbetsgivare && !namn && !inkomst && !epost && !sysselsattning && !tel && !telalt && !hyresvard && !gatuadress && !postadress && !referens)) {
    return (<div>Ingen medsökande</div>);
  } else {
    return (
      <table border="0">
        <tbody>
          <tr>
            <th width="70px" align="left">pnr:</th>
            <td style={{ paddingRight: "30px" }}>{pnr}</td>
            <th width="70px" align="left">arbete:</th>
            <td>{arbetsgivare}</td>
          </tr>
          <tr>
            <th align="left">namn:</th>
            <td style={{ paddingRight: "30px" }}>{namn}</td>
            <th align="left">inkomst:</th>
            <td>{inkomst}</td>
          </tr>
          <tr>
            <th align="left">epost:</th>
            <td style={{ paddingRight: "30px" }}>{epost}</td>
            <th align="left">syssla:</th>
            <td>{sysselsattning}</td>
          </tr>
          <tr>
            <th align="left">tel:</th>
            <td style={{ paddingRight: "30px" }}>{tel} {(telalt != null) ? "&bull; " + telalt : ""}</td>
            <th align="left">hyresv.:</th>
            <td>{hyresvard}</td>
          </tr>
          <tr>
            <th align="left">adress:</th>
            <td style={{ paddingRight: "30px" }}>{gatuadress}, {postadress}</td>
            <th align="left">ref.:</th>
            <td>{referens}</td>
          </tr>
        </tbody>
      </table>
    )
  }
}

const expandRow = {
  renderer: (row, rowIndex) => {
    return (
      <div style={{ background: '#f6f6f6' }}>
        <table style={{ marginLeft: "30px" }}>
          <tbody>
            <tr>
              <td>
                <Subgrid
                  isFirst={true}
                  pnr={row.pnr1}
                  arbetsgivare={row.arbetsgivare1}
                  namn={row.namn1}
                  inkomst={row.inkomst1}
                  epost={row.epost1}
                  sysselsattning={row.sysselsattning1}
                  tel={row.tel1}
                  telalt={row.telalt1}
                  hyresvard={row.hyresvard1}
                  gatuadress={row.gatuadress1}
                  postadress={row.postadress1}
                  referens={row.referens1}
                />
                <br />
                <Subgrid
                  isFirst={false}
                  pnr={row.pnr2}
                  arbetsgivare={row.arbetsgivare2}
                  namn={row.namn2}
                  inkomst={row.inkomst2}
                  epost={row.epost2}
                  sysselsattning={row.sysselsattning2}
                  tel={row.tel2}
                  telalt={row.telalt2}
                  hyresvard={row.hyresvard2}
                  gatuadress={row.gatuadress2}
                  postadress={row.postadress2}
                  referens={row.referens2}
                />
              </td>
              <td style={{ verticalAlign: "top", paddingLeft: "30px" }}>
                <strong>&ouml;nskem&aring;l: </strong>{row['checkbox_bilplats']}
                <br />
                {row['onskemal']}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
};
