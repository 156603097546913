import axios from 'axios';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_BACKEND_API_URL;
const path = `${API_URL}/authenticateWith/email`;

export const verificationCodeSend = async (data) => {
  console.log("api login service for email");
  return axios
    .post(`${path}/verification-code-send`, data)
    .then((response) => {
      console.info('ok');
      console.info(response);

      return response;
    })
    .catch((error) => {
      console.error('ApiLoginService> Error in verification code send:' + JSON.stringify(error));
      throw error;
    });
};

// use for checking password for an email
export const checkIsLoggedIn = async () => {
  return axios
    .get(`${path}/check`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error('ApiLoginService> Error check logged in:' + JSON.stringify(error));
      throw error;
    });
};

export const verificationCodeVerify = async (data) => {
  return axios
    .post(`${path}/verification-code-verify`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error('ApiLoginService> Error verify:' + JSON.stringify(error));
      throw error;
    });
};

export const logout = async () => {
  return axios
    .post(`${path}/logout`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error('ApiLoginService> Error logout:' + JSON.stringify(error));
      throw error;
    });
};
