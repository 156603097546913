import { BsArrowReturnLeft } from 'react-icons/bs'; // import the icon from react-icons
import { BsPower } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

const ModalComponent = ({ title, onLogout, onReset, onHide, show, children }) => {
  return (
  <Modal
    show={show}
    onHide={onHide}  // triggers if clicked outside modal
  >
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
      <div>
        {onLogout && (
          <Button
            onClick={onLogout}
            variant="link"
            className="close text-secondary"
            title="Logga ut"
            style={{
              fontSize: '1.5rem',
              lineHeight: '1',
              marginLeft: 'auto',
              marginRight: '10px',
              padding: '0',
              textDecoration: 'none',
            }}
          >
            <BsPower />
          </Button>
        )}
        {onReset && (
          <Button
            onClick={onReset}
            variant="link"
            className="mt-1 close text-secondary"
            title="Börja om"
            style={{
              fontSize: '1rem',
              lineHeight: '1',
              marginLeft: 'auto',
              marginRight: '10px',
              padding: '0',
              textDecoration: 'none',
            }}
          >
            <BsArrowReturnLeft />
          </Button>
        )}
        <Button
          onClick={onHide}
          variant="link"
          className="close text-secondary"
          title="Stäng"
          style={{
            fontSize: '1.5rem',
            lineHeight: '1',
            marginRight: '10px',
            padding: '0',
            textDecoration: 'none',
          }}
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="p-2 custom-modal-content" style={{
        // Add your custom styles here
        background: 'linear-gradient(to bottom, #f3f3f3, #ffffff)',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}>
        {children}
      </div>
    </Modal.Body>
  </Modal>
)};

export default ModalComponent;
