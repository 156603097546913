import './../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import Employees from '../components/Employees';
import React from "react";


const Om = () => {
  return (
    <Container>
      <Row id="om" className="px-4 mb-5">
        <h2>Om Umehem</h2>
        <p>
          Fastighetsbolaget Umehem grundades år 2006 av Anders Lindgren och Ulf Sjödin i Umeå.
          Vi förvaltar 80'000 kvadratmeter bostäder och kommersiella lokaler.
        </p>
        <p>
          I Umehemkoncernen ingår även
          byggbolaget <a href="https://www.arbygg.se">AR Bygg</a> samt
          <a href="https://www.artark.se">AR.T</a> som främst arbetar med arkitektur, detaljplaner
          och bygglov.
        </p>
        <p>
          Vårt team består av totalt 41 personer inom koncernen, alla med olika expertområden och
          färdigheter. Tillsammans kan vi ta på oss en mängd olika byggprojekt, från nybyggnationer
          till renoveringar och ombyggnationer av bostäder, kontor och industrilokaler. Vi strävar
          alltid efter att vara en trygg, långsiktig och ledande partner för våra hyresgäster.
        </p>
        <p>
          Vi är övertygade om att våra medarbetare är vårt mest värdefulla tillgång. Därför strävar
          vi alltid efter att skapa en positiv arbetsmiljö där alla trivs och känner sig trygga. Vi
          är stolta över vårt höga fokus på kvalitet och service, och det är viktigt för oss att
          alla anställda delar vår vision och är engagerade i att uppnå gemensamma mål. Vi tror på
          ärlighet, respekt, solidaritet och lyhördhet som grundläggande principer i allt vi gör,
          och vi uppmuntrar våra medarbetare att dela med sig av sina erfarenheter och bidra till
          ett sammanhållet arbetsklimat.
        </p>
        <Employees/>
      </Row>
    </Container>
  );
};

export default Om;
