import { useState } from 'react';
import ModalComponent from './modal/ModalComponent';

const FelanmalanModal = ({ onClose, onLogout }) => {
  const [showModal, setShowModal] = useState(true);
  const renderModalContent = () => {
    return (
      <>
        <h4>I akutfall</h4>
        <p>
          För akuta ärenden som vattenläckor, icke-fungerande kyl/frys, krossade rutor, elfel och
          totalstopp i avloppet, ring oss omedelbart
          på <a target="_blank" rel="noopener noreferrer" href="tel:+4690133950">+46 90 13 39 50</a>. Under kvällstid och helger
          kopplas detta nummer automatiskt vidare till vår jourtelefon.
        </p>

        <h4>För icke-akuta ärenden</h4>
        <p>
          Vid mindre brådskande ärenden, till exempel droppande kranar, eller om tvättmaskinen,
          torktumlaren eller spisplattan inte fungerar, vänligen kontakta vårt kontor
          på <a target="_blank" rel="noopener noreferrer" href="tel:+4690133950">+46 90 13 39 50</a>.
          Vi är tillgängliga mån-fre mellan 8:00 och 12:00.
        </p>

        <h4>Vanliga frågor och svar</h4>
        <h5>Strömavbrott?</h5>
        <p>
          Kontrollera först om strömavbrottet endast gäller ditt hem eller hela området. Se över dina
          säkringar och byt ut någon som verkar vara trasig.
        </p>
        <h5>Trasiga glödlampor?</h5>
        <p>
          Kom ihåg att glödlampor, lysrör och glimtändare är förbrukningsmaterial som hyresgästen
          ansvarar för att byta ut.
        </p>

        <h5>Problem med bredband, telefon eller TV?</h5>
        <p>I detta fall, vänligen kontakta din tjänsteleverantör direkt.</p>

        <h5>Avloppsproblem?</h5>
        <p>
          Mindre avloppsproblem som stopp i handfatet, köket eller duschutrymmet kan oftast lösas
          enkelt. Vi uppmuntrar hyresgästen att först försöka rensa dessa själv.
        </p>
      </>
    );
  }

  return (
    <ModalComponent
      title={"Felanmälan"}
      onHide={() => { setShowModal(false); onClose() }}
      show={showModal}
    >
      {renderModalContent()}
    </ModalComponent>
  );
};

export default FelanmalanModal;
