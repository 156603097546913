import React, { useState, createContext, useContext } from "react";

// Admin context
const AdminContext = createContext([{}, () => {}]);
export const useAdmin = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
  // this will be set upon wrapping
  const [admin, setAdmin] = useState({ isAuthenticated: false, email: null });
  // Additional Admin-specific state and functionality goes here...
  return (
    <AdminContext.Provider value={[admin, setAdmin]}>
      {children}
    </AdminContext.Provider>
  );
};
