import { useAdmin } from '../../../contexts/AdminContext';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as ApiLoginWithPasswordService from '../../../services/authenticateWith/password/ApiLoginWithPasswordService';
import LoginForm from './LoginForm';
import ModalComponent from '../../modal/ModalComponent';

const Login = ({ Component }) => {
  const [showModal, setShowModal] = useState(true);
  const [admin, setAdmin] = useAdmin();
  const [alert, setAlert] = useState({ variant: null, message: null, count: 0 });

  // navigate hook
  const navigate = useNavigate();

  // mutations (used to be able to track state of request, e.g., loading, complete, ...)
  const mutationOptions = {
    onSuccess: () => {
      console.info('LoginModal > mutationOptions > Success');
    },
    onError: (error) => {
      console.error('LoginModal > mutationOptions > Error: ', error);
    },
    onSettled: (data, error, variables, context) => {
      console.info('LoginModal > mutationOptions > Settled: ', JSON.stringify(data));
    }
  };

  const checkIsLoggedInMutation = useMutation(
    () => ApiLoginWithPasswordService.checkIsLoggedIn(),
    mutationOptions
  );

  const checkIsLoggedIn = async () => {
    await checkIsLoggedInMutation.mutateAsync()
      .then(response => {
        if (response.data.isLoggedIn) {
          console.log("already authenticated (by password)");
          setAdmin({ isAuthenticated: true, email: response.data.email });
        } else {
          setAdmin({ isAuthenticated: false, email: null });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!admin.isAuthenticated) {
      checkIsLoggedIn();
    }
    // eslint-disable-next-line
  }, [admin.isAuthenticated]);

  const handleSubmitMutation = useMutation(
    ({ data }) => ApiLoginWithPasswordService.passwordVerify(data),
    mutationOptions
  );

  const handleLogoutMutation = useMutation(
    () => ApiLoginWithPasswordService.logout(),
    mutationOptions
  );

  const handleSubmit = async (data) => {
    const response = await handleSubmitMutation.mutateAsync({ data });
    const message = response.data.message;
    console.log(response.data.message);
    if (response.data.loggedIn === true) {
      // Successfully logged in
      console.log("succeeded to log in");
      setAlert({ variant: "info", message: message, count: 0 });
      setAdmin(prev => ({ isAuthenticated: true, email: data.email }));
    } else if (response.data.status === "retry") {
      setAlert(prev => ({
        variant: "danger",
        message: message,
        count: prev.message === message ? prev.count + 1 : 0,
      }));
    } else {
      setAlert(prev => ({
        variant: "danger",
        message: message,
        count: prev.message === message ? prev.count + 1 : 0,
      }));
    }
  };

  const handleLogout = async () => {
    const response = await handleLogoutMutation.mutateAsync();
    console.log(response.data);
    const message = response.data.message;
    if (response.data.loggedOut === true) {
      console.error('Logging out.');
      setAdmin(prev => ({ isAuthenticated: false, email: null }));
      setAlert(prev => ({
        variant: "info",
        message: message,
        count: prev.message === message ? prev.count + 1 : 0,
      }));
    } else {
      setAlert(prev => ({
        variant: "danger",
        message: 'Could not logout from the server. ' + message,
        count: prev.message === message ? prev.count + 1 : 0,
      }));
    }
    navigate('/control');
  };

  const { isLoading } = checkIsLoggedInMutation;

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (admin.isAuthenticated) {
    console.log("trying to render intresseanmalan");
    return <Component onLogout={handleLogout} />;
  } else {
    return (
      <ModalComponent
        title={"Logga in till adminpanelen"}
        onHide={() => { setShowModal(false); navigate('/'); }}
        show={showModal}
      >
        <LoginForm
          handleSubmit={handleSubmit}
          alert={alert}
        />
      </ModalComponent>
    );
  }
};

export default Login;
