import './../App.css';
import { faBlog, faPenAlt, faWrench, faHouse, faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import ModalWithButton from './modal/ModalWithButton';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React from 'react';
import { FaClock, FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

// modals
import UserApp from './intresseanmalan/user/UserApp';
// import KontaktModal from './KontaktModal';
import HyresInfoModal from './HyresInfoModal';
import FelanmalanModal from './FelanmalanModal';
import LedigtModal from './LedigtModal';

axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const navigationItems = [
  {
    component: LedigtModal,
    icon: faHouse,
    text: 'Ledigt',
    path: '/ledigt',
    id: "ledigt-link"
  },
  {
    component: UserApp,
    icon: faPenAlt,
    text: 'Intresseanmälan',
    path: '/intresseanmalan',
    id: "intresseanmalan-link"
  },
  {
    component: FelanmalanModal,
    icon: faWrench,
    text: 'Felanmälan',
    path: '/felanmalan',
    id: "felanmalan-link"
  },
  {
    component: HyresInfoModal,
    icon: faInfo,
    text: 'Hyresinfo',
    path: '/hyresinfo',
    id: "hyresinfo-link"
  },
  // {
  //   component: KontaktModal,
  //   icon: faPhone,
  //   text: 'Kontakt',
  //   path: '/kontakt',
  //   id: "kontakt-link"
  // },
];

function Navigation({ activeModal, setActiveModal }) {

  // other states
  const [countLedigt, setCountLedigt] = useState(0);
  const [countNews, setCountNews] = useState(0);

  const [navbarExpanded, setNavbarExpanded] = useState(false);

  // Step 2: Set up a handler to toggle navbar state
  const handleNavbarToggle = (expanded) => {
    setNavbarExpanded(expanded);
  };

  // use location
  const location = useLocation();

  useEffect(() => {
    axios.get(`${API_URL + "/json/ledigt"}`)
      .then(response => {
        const count = response.data.filter(offer => offer.show).length;
        setCountLedigt(count);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
    axios.get(`${API_URL + "/news/posts"}`)
      .then(response => {
        const count = response.data.filter(offer => offer.show && !offer.archive).length;
        setCountNews(count);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, []);

  return (
    <Navbar
      sticky="top"
      style={{ boxShadow: "5px 5px 5px lightgray" }}
      expand="lg"
      className="bg-secondary text-white mb-2"
      expanded={navbarExpanded}
      onToggle={handleNavbarToggle}
    >
      <Container>
        <Navbar.Brand className="text-white" as={Link} to="/">
          <img className="d-block" width="200px" src={process.env.PUBLIC_URL + "/images/logo/umehemWhite2.png"} alt="umehem" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav className="mr-auto app-dashboard">
            <NavLink
              className="nav-link text-white"
              style={location.pathname === "/blog" ? { fontWeight: "bold" } : null}
              to="/blog"
              onClick={() => setNavbarExpanded(false)}
            >
              <div style={{ textAlign: 'center', color: '#ffffff' }}>
                <FontAwesomeIcon icon={faBlog} />
                <sup style={{ fontSize: '1em' }}>{countNews}</sup>
                <p>Nyheter</p>
              </div>
            </NavLink>
            {navigationItems.map((item, index) => (
              <ModalWithButton
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                modalId={item.id}
                key={index}
                button={({ onClick }) => (
                  <NavLink
                    as={Link}
                    className="nav-link text-white"
                    style={location.pathname === item.path ? { fontWeight: "bold" } : null}
                    onClick={() => { onClick(); }}
                  >
                    <div style={{ textAlign: 'center', color: '#ffffff' }}>
                      <FontAwesomeIcon icon={item.icon} />
                      {item.component === LedigtModal && countLedigt && (
                        <sup style={{ fontSize: '1em' }}>{countLedigt}</sup>
                      )}
                      <p>{item.text}</p>
                    </div>
                  </NavLink>
                )}
                modal={item.component}
                setCountLedigt={setCountLedigt}
              />
            ))}
          </Nav>
          <Nav className="me-auto">
          </Nav>
        </Navbar.Collapse>
        <div className="text-white" style={{minWidth: "250px", marginRight: "10px"}}>
          <p>
            <FaClock title="Öppettider"/> Öppet vardagar 08:00-12:00<br />
            <FaMapMarkerAlt title="Kontorsadress"/> <a style={{ color: 'white' }} target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/c2nTGemMC5ZqSFxcA">
              Nygatan 13, 903 27 Umeå
            </a>
            <br />
            <span><FaPhone title="Telefonnummer"/> <a style={{ color: 'white' }} target="_blank" rel="noopener noreferrer" href="tel:+4690133950">+46 90 13 39 50</a></span> <br />
            <span><FaEnvelope title="Emails"/> <a style={{ color: 'white' }} href="mailto:info@umehem.se">info@umehem.se</a></span>
          </p>
        </div>
      </Container>
    </Navbar>
  );
}

export default Navigation;
