import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import Data from "../resources/carousel.json";

const styles = {
  card: {
    backgroundColor: '#B7E0F2',
    borderRadius: 55,
    padding: '3rem'
  },
  cardImage: {
    objectFit: 'cover',
    // borderRadius: 55,
    // width: '18rem',
    // height: '60vh'
    width: '100%', // use full width of the container
    height: 'auto' // keep the aspect ratio
  },
  caption: {
    color: 'white',
    backgroundColor: 'rgba(128, 128, 128, 0.5)',
    padding: '0',
    margin: '0'
  }
}

const IntroCarousel = () => {
  return (
    <Carousel>
      { Data.map(post =>
         <Carousel.Item key={post.id}>
           <img
             style={styles.cardImage}
             className="d-block w-100" // h-20
             src={process.env.PUBLIC_URL + post.image}
             alt={post.title}
           />
           <Carousel.Caption>
            <h1 style={styles.caption}>{post.title}</h1>
            <h2 style={styles.caption}>{post.description}</h2>
           </Carousel.Caption>
         </Carousel.Item>
      )}
    </Carousel>
  )
};

export default IntroCarousel;
