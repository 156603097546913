import { Alert, Button, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

axios.defaults.withCredentials = true;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Renew = ({ setActiveModal }) => {
  let query = useQuery();

  const [params] = useState({
    id: query.get("id"),
    challenge: query.get("challenge"),
  });

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(`${API_URL}/intresseanmalan/automation/renew`);
    axios
      .get(`${API_URL}/intresseanmalan/automation/renew`, { params : params })
      .then(response => {
        console.log(response);
        setData(response.data);
        setError(null);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setData(null);
        setError(err.response.data);
        setLoading(false);
      });
  }, [params]);

  if (loading) return <Container>Loading...</Container>;

  return (
    <Container className='mt-5'>
      <h3>Förlängning av intresseanmälan</h3>
      { error && <Alert variant="danger">{error.message}</Alert> }
      { data && <Alert variant="success">{data.message}</Alert> }
      <Alert variant="primary">
        OBS! Du kan se/ändra/radera/förlänga/skapa dina intresseanmälan genom att logga in
        på <Button onClick={() => setActiveModal('intresseanmalan-link')}>intresseanmälan</Button>.
      </Alert>
    </Container>
  );
}

export default Renew;
