import { useEffect, useRef } from 'react'; //useState
import { Loader } from '@googlemaps/js-api-loader';
import allMarkers from '../resources/realEstates.json';

export default function MyMap({selectedMarkerId} ) {

  const googlemap = useRef(null);
  const googleMarkers = useRef([]);
  // const [infoWindow, setInfoWindow] = useState(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
    });

    let map;
    loader.load().then(() => {
      const google = window.google;
      map = new google.maps.Map(googlemap.current, {
        center: { lat: 63.824, lng: 20.255000 },
        zoom: 13.7,
        mapId: 'my-google-map',
        fullscreenControl: true,
        mapTypeControl: true,
        streetViewControl: true,
        zoomControl: true,
        scrollwheel: false,
      });

      // const infoWindow = new google.maps.InfoWindow();
      // setInfoWindow(infoWindow);

      google.maps.event.addListenerOnce(map, 'idle', () => {

        allMarkers.forEach((markerData, index) => {
          const marker = new google.maps.Marker({
            position: {lat: markerData.lat, lng: markerData.lng},
            map: map,
            title: markerData.name,
            id: "marker" + markerData.idx,
            icon: process.env.PUBLIC_URL + "/images/mapicons/largeTD" + markerData.color + "Icons/marker" + markerData.idx + ".png"
          });

          const contentString = `
            <div >
              ${markerData.address}
              <br />
              <img
                src="${process.env.PUBLIC_URL}/images/fastigheter/${markerData.id}/${markerData.cardImage}"}
                alt="${markerData.name}"
                style="width: 100%; max-width: 200px; height: auto'"
              />
            </div>
          `;
          const infowindow = new google.maps.InfoWindow({
            content: contentString
          });
          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });
          googleMarkers.current.push(marker);
        });
      });

      allMarkers.forEach((markerData, index) => {
        const test = document.getElementById("openMarkerLink-" + markerData.idx);
        test && test.addEventListener("click", () => {
          google.maps.event.trigger(googleMarkers.current[markerData.idx - 1], "click");
        }, false);
      });
    });
  }, []);

  useEffect(() => {
    if (selectedMarkerId && window.google) {
      // works the first time, but need to cancel selectedMarkerId upon closing info window
      window.google.maps.event.trigger(googleMarkers.current[selectedMarkerId - 1], 'click');
    }
  }, [selectedMarkerId]);

  return (
    <div className="h-100" style={{ minHeight: "900px" }}>
        <div id="map" style={{ height: "100%"}} ref={googlemap}></div>
    </div>
);
}
